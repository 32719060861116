<ng-container [ngSwitch]="true">

  <span [class.heading--3]="hasHeadings" *ngSwitchCase="formData.Q658 == 'Motability' || formData.Q658 == 'Service Plan with MOT'  || formData.Q658 == 'Service Plan without MOT'  || formData.Q658 == 'No' && !(formData?.dealerServiceControls?.locnDsc?.includes('Mercedes'))">
    {{ serviceResponse?.servOptionCosts?.servTotalServiceCostLbl }}
  </span>

  <span [class.heading--3]="hasHeadings" *ngSwitchCase="formData.isPOA">
    <span class="font--light text--medium-grey">Total cost:</span>
    POA {{!formData.totalPrice ? '' : ' + ' + (formData.totalPrice | currency:'&pound;') }}
  </span>

  <ng-container
    *ngSwitchCase="formData.totalPrice !== '' && formData.totalPrice !== 'No Service Selected' && !formData.isPOA 
    || formData?.dealerServiceControls?.locnDsc?.includes('Mercedes') && formData.totalPrice !== '' && formData.totalPrice !== 'No Service Selected' && !formData.isPOA">
    <span [class.heading--5]="hasHeadings"
      *ngIf="hasDiscount && serviceResponse?.servOptionCosts?.originServTotalServiceCost">
      <span
        style="text-decoration: line-through;">{{ serviceResponse?.servOptionCosts?.originServTotalServiceCost | currency:'&pound;' }}</span>
      <span class="font--bold pill-nav__item margin-left-10">
        {{ totalDiscount }}% OFF</span>
    </span>
    <span [class.heading--3]="hasHeadings">
      <span class="font--light text--medium-grey">Total cost:</span>
      {{formData.totalPrice === 'No Service Selected' ? 'N/A' : (formData.totalPrice | currency:'&pound;') }}
    </span>
  </ng-container>

</ng-container>
