/**
 * Various configuration constants for brand localizations.
 */

export enum BRANDS {
    CAR = 'car',
    MOTORCYCLE = 'motorcycle'
}
/**
 * Config settings for car and motorcycle brands.
 */
export const BRAND_CONFIGS: { [key in BRANDS]: { [key: string] : string} } = {
    car: {
        appBaseHref : 'my-car',

        title : 'My Car',
        vehicle: 'vehicle',
        vehicles: 'vehicles',

        usedVehiclesHref : 'used-cars',
        newVehiclesInStockHref : 'new-cars-in-stock',
        newVehicleDealsHref : 'new-car-deals',
        exDemoVehiclesHref : 'ex-demo-cars',

        servicedVehicleTypes : 'any',

        //myVehicleFallbackImg : 'https://www.bristolstreet.co.uk/custom/87364.jpg',
        myVehicleFallbackImg : '/custom/14936.jpg',
        //loginRecentlyViewedImg : 'https://www.bristolstreet.co.uk/custom/87309.png',
        loginRecentlyViewedImg : '/custom/38862.png',

        faVehicleIcon : 'fal fa-car',
        faCrashIcon : 'fal fa-car-bump',
        faVehicleSideIcon : 'fal fa-car-side',
        faMechanicIcon : 'fal fa-car-mechanic',

        hideMotabilityOptions : 'false',
    },
    motorcycle: {
        appBaseHref : 'my-motorcycle',

        title : 'My Motorcycle',
        vehicle: 'motorcycle',
        vehicles: 'motorcycles',

        usedVehiclesHref : 'used-motorcycles',
        newVehiclesInStockHref : 'new-motorcycles-in-stock',
        newVehicleDealsHref : 'new-motorcycle-deals',
        exDemoVehiclesHref : 'ex-demo-motorcycles',

        servicedVehicleTypes : 'Bike',

        myVehicleFallbackImg : 'https://www.bristolstreet.co.uk/custom/87364.jpg',
        loginRecentlyViewedImg : 'https://www.bristolstreet.co.uk/custom/87309.png',

        faVehicleIcon : 'fas fa-motorcycle',
        faCrashIcon : 'fal fa-car-bump',
        faVehicleSideIcon : 'fas fa-motorcycle',
        faMechanicIcon : 'fal fa-car-mechanic',

        hideMotabilityOptions : 'true',
    }
}
export enum VEHICLES {
    CAR = 'car',
    MOTORCYCLE = 'motorcycle'
}
/**
 * Lookup table to translate vehicle types.
 */
export const VEHICLE_TYPE_MAP: Map<VEHICLES, string[]> = new Map([
    [VEHICLES.CAR, ['car']],
    [VEHICLES.MOTORCYCLE, ['motorcycle', 'bike']]
]);