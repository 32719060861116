import { Component, Input, OnInit } from '@angular/core';
import { FormDataService } from './services/form-data.service';
import { from } from 'rxjs';
import { MyGlobal } from './models/formData';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'service-booking';
  @Input() formData;
  @Input() enquiryCreate;
  @Input() addressList;

   constructor(private formDataService: FormDataService) {
   }

   // tslint:disable-next-line:use-life-cycle-interface
   ngOnInit() {
        this.formData = this.formDataService.getFormData();
        this.enquiryCreate = this.formDataService.getEnquiryData();
        this.addressList = this.formDataService.getAddressLineData();
        // console.log(this.title + ' loaded!');
    }
}

export abstract class GlobalRef {
  abstract get nativeGlobal(): MyGlobal;
}
