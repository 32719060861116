// Vehicle Details Component
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { VehicleLookupService } from '../../services/vehicle-lookup.service';
import { Vehicle } from '../../models/vehicle';
import { ServiceDetails } from '../../models/serviceDetails';
import { PostcodeLookupService } from '../../services/postcode-lookup.service';
import { Location } from '../../models/location';
import { FormData, EnquiryCreate, AddressList } from '../../models/formData';
import { GetServiceDetailsService } from '../../services/get-service-details.service';
import { FormDataService } from '../../services/form-data.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, skip } from 'rxjs/operators';
//import cssVars from 'css-vars-ponyfill';
import { BrandsConfigService } from 'src/app/services/brands-config.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { MatPseudoCheckbox, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
// import { ThrowStmt } from '@angular/compiler';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm
  }]
})

export class VehicleDetailsComponent implements OnInit, AfterViewInit {
  //farnelllandrover or farnelljaguar //
  farnell: boolean;
  selfCheckedSplan: boolean;
  motabilityPlan: boolean;
  notMotabNotSplan: boolean;
  selfCheckedMOTYes: boolean;
  selfCheckedMOTNO: boolean;

  // end //
  allAddresses: any;
  location: Location;
  vehicle: Vehicle = null;
  formData: FormData;
  enquiryCreate: EnquiryCreate;
  serviceDetails: ServiceDetails;
  // addressLines: AddressList;
  addressList: AddressList;
  vehicleFound: boolean;
  isServicedVehicleType: boolean;
  vehicleBeingFetched: boolean;
  postCodeBeingFetched: boolean;
  postcodeFound: boolean;
  emptyField: boolean;
  // ngModel of registration declared as string for use in RxJS subject
  registrationModel: string;
  // RxJS registration subject
  registrationChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  registristrationChangeObservable: Observable<string> = this.registrationChangeSubject.asObservable();
  // ngModel of postcode declared as string for use in RxJS subject
  isJlr: boolean = window.location.href.search('farnelljlr') !== -1 ? true : false;
  postCodeRegex: RegExp = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/gi;
  isValidPostCode: boolean = false;

  postcodeModel: string;
  // RxJS postcode subject
  postcodeChangeSubject: Subject<string> = new Subject<string>();
  myElement: any;
  @ViewChild('vehicleReg') vehicleReg: any;

  servTypeSelected = true;
  vehicleIsMercedes = false;
  vehicleAllowed: boolean = true;
  isTaxiCentre: boolean;

  constructor(private vehicleLookupService: VehicleLookupService,
    private getServiceDetailsService: GetServiceDetailsService,
    private formDataService: FormDataService,
    private postcodeLookupService: PostcodeLookupService,
    private brandsConfigService: BrandsConfigService,
    ) { }

  ngOnInit() {
    this.formData = this.formDataService.getFormData();
    this.enquiryCreate = this.formDataService.getEnquiryData();
    // The below code is to read the reg value from the session storage if
    // it comes from a widget such as the find a dealer widget
    if (sessionStorage.getItem('car reg') !== null) {
      this.formData.vehicleReg = sessionStorage.getItem('car reg');
      // this.getVehicleDetails();
      this.onRegistrationChange(this.formData.vehicleReg);
    }

    // this.formData.hasServicePlan = true;
    // RxJS subject based debouncing, runs API fetch on keyup once a second
    this.registristrationChangeObservable.pipe(

      tap((e) => {
        if (!e) {
          this.emptyField = true;
        }
        return this.vehicleBeingFetched = true;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      tap((e) => {
        if (e) {
          this.emptyField = false;
          this.vehicleLookupService.findVehicle(e);
        }
      }),
      switchMap(model => {
        if (!this.emptyField) {
          return this.vehicleLookupService.findVehicle(model);
        } else {
          return [];
        }
      })).subscribe(
        (data: any) => {
          this.vehicle = data;
          this.selfCheckedSplan = (this.vehicle['hasServicePlan'] === 'yes') ? true : this.selfCheckedSplan;
          this.selfCheckedMOTYes = (this.vehicle['hasServicePlanWithMOT'] === 'yes') ? true : this.selfCheckedMOTYes;
          this.vehicleBeingFetched = false;
          if (this.vehicle.vehicle) {
            this.formDataService.setVehicle(this.vehicle);
            // console.log(this.vehicle);
            this.checkAllowedVehicles();
            this.vehicleFound = true;
          } else {
            this.vehicleFound = false;
            console.log('Vehicle not found!');
          }
          // Check whether vehicle type is supported for servicing on current brand
          this.isServicedVehicleType =
            this.brandsConfigService.getConfigValue('servicedVehicleTypes').includes(this.vehicle.vehTyp)
            || (this.brandsConfigService.getConfigValue('servicedVehicleTypes') === "any");
        },
        (err: any) => console.log(`getVehicleDetails encountered an error : ${err}`)
      );
    // same for postcode
    this.postcodeChangeSubject.pipe(tap(() => this.postCodeBeingFetched = true), debounceTime(1000), distinctUntilChanged()).subscribe(
      model => {
        this.formData.postcode = model;
        this.postCodeBeingFetched = false;
        this.isValidPostCode = this.postCodeRegex.test(model);
        if (this.isValidPostCode) {
          this.getAddressList();
        }
      });

    this.isTaxiCentre = window.location.href.includes('thetaxicentre');
  }

  ngAfterViewInit() {
    this.formDataService.setDataLayer(window.location.href + 'vehicle-details', 'Vehicle details page of service booking form');
    (window as any).dataLayer.push({ event: 'serviceBookingVehicleDetails' });
    // console.log(`This is the state of the vehicle input: ${this.vehicleReg.valid}`);
  }

  updateDataLayer() {
    this.formDataService.setDataLayer(window.location.href + 'service-details', 'Service details page of service booking form');
    this.formDataService.setDataLayerDetails();
  }

  /*testing() {
       const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
       this.http.get('https://www.bristolstreet.co.uk/content/api/json_veh.p?regno=VK12NWS').subscribe(
           vals => console.log(vals),
           err => console.log(`There was an error while making this http request: ${err}`));
  }*/

  onServTypeChanged() {
    if (this.formData.servType === '' && this.formData.seasonalCheck === '' && this.formData.tyreCheck === '' && this.formData.adBlue === '' && this.formData.generalRepMain === '' && this.formData.recall === '' && this.formData.healthCheck === '') {
      this.servTypeSelected = false;
    } else {
      this.servTypeSelected = true;
    }
  }

  // Fetches vehicle details by sending a Vehicle Registration, returns empty array if not found
  /*getVehicleDetails() {
      this.vehicleLookupService.findVehicle(this.formData.vehicleReg).subscribe(
          (data: any) => {
              this.vehicle = data;
              if (this.vehicle.vehicle) {
                  this.formDataService.setVehicle(this.vehicle);
                  this.vehicleFound = true;
              } else {
                  this.vehicleFound = false;
              }
          },
          (err: any) => console.log(`getVehicleDetails encountered an error : ${err}`)
      );
  }*/

  // Sends the inputted registration to RxJs Subject
  onRegistrationChange(query: string) {
    this.formData.vehicleReg = query.toUpperCase();
    this.registrationChangeSubject.next(this.formData.vehicleReg);
  }

  // removes error state from Registration Input
  unsetVehicleFound() {
    this.vehicleFound = null; // do you mean false?
  }

  // API call to check for valid postcode. Returns empty array if postcode not found.
  getAddressList() {
    this.postcodeLookupService.getAllAddresses(this.postcodeModel).subscribe(
      (data: any) => {
        this.addressList = data.Addresslines;
        if (this.addressList[0]) {
          this.formDataService.setAddressList(data);
          this.postcodeFound = true;
          this.formData.postcodeFound = true;
        } else {
          this.postcodeFound = false;
          this.formData.postcodeFound = false;
        }
      },
      (err: any) => console.log('Error with getAllAddressList'),
    );
  }

  // isHonda() {
  //     const url: string = window.location.href;
  //     return (url.includes('vertuhonda')) ? true : false;
  // }

  // Sends the inputted postcode to RxJs Subject
  onPostcodeChange(query: string) {
    this.postcodeChangeSubject.next(query.toUpperCase());
  }

  // removes error state from Postcode Input
  unsetPostcodeFound() {
    this.postcodeFound = null;
  }

  getServiceQuote() {
    if (this.selfCheckedSplan) {
      if (this.formData.hasServicePlan = true && this.selfCheckedMOTYes === true) {
        this.formData.Q658 = 'Service Plan with MOT';
      } else if (this.formData.hasServicePlan = true && (this.selfCheckedMOTNO === true || this.selfCheckedMOTNO === undefined)) {
        this.formData.Q658 = 'Service Plan without MOT';
      }
    }

    if (this.formData.hasServicePlan) {
      this.formDataService.setSelectedService(1);
    }

    this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, '', this.formData).subscribe(
      (data: any) => {
        this.serviceDetails = data;
        try {
          this.formData.calendarStartDate = new Date(parseInt(Object.keys(data.dealerAvailability)[0].substr(6, 4)),
            parseInt(Object.keys(data.dealerAvailability)[0].substr(3, 2)) - 1);
        } catch (err) {
          this.formData.calendarStartDate = null;
        }
        this.formData.totalPrice = data.servOptionCosts.servTotalServiceCost;
        this.formDataService.formData.servOptionCosts = data.servOptionCosts;
      },
      (err: any) => console.log(err),
      () => this.formDataService.setServiceDetails({
        ...this.serviceDetails
      })
    );
    if (this.formData.servType === 'MOT') {
      this.formData.isMOT = true;
      this.formData.intervalMonth = 'MOT';
    }

    this.formDataService.setEnquiryJSONCreate(this.formData, this.vehicle);
    this.formDataService.scrollToTop();
  }

  checkAllowedVehicles() {
    if (this.isTaxiCentre) {
      this.vehicleAllowed = this.vehicle.make !== 'LTI';
    }
    // if (this.isJlr) {
    //   if (this.vehicle.make !== 'Jaguar' && this.vehicle.make !== 'Land Rover') {
    //     this.vehicleAllowed = false;
    //   } else {
    //     this.vehicleAllowed = true;
    //   }
    // }
  }
}
