import { Injectable } from '@angular/core';
import { BRANDS, BRAND_CONFIGS, VEHICLES, VEHICLE_TYPE_MAP } from '../utils/config';

/**
 * Provides text/url localizations for the car/motorcycle brands depending on the window.location.href.
 */
@Injectable({
    providedIn: 'root'
})
export class BrandsConfigService {
    private brand: BRANDS;

    /**
     * Initialise the brand configuration depending on the current url.
     */
    constructor() {
        const href = window.location.href;
        if (href.includes('vertumotorcycles')
            // || window.location.href.includes('localhost')
        ) {
            this.brand = BRANDS.MOTORCYCLE;
        } else {
            this.brand = BRANDS.CAR;
        }
    }

    /**
     * Retrieve the current brand's config value for the given key. 
     * Use @function getVehicleName to retrieve the current environment's vehicle name translation.
     * @param key 
     */
    getConfigValue(key: string): string {
        return BRAND_CONFIGS[this.brand][key];
    }

    /**
     * Check the current brand's configuration whether a property/field should be hidden.
     * @param property 
     */
    isHidden(property: string): boolean {
        if (BRAND_CONFIGS[this.brand][`hide${property.charAt(0).toUpperCase() + property.slice(1)}`] === 'true')
            return true;
        return false;
    }

    /**
     * Get the vehicle name for the given vehicle type using the lookup table or, if omitted,
     * look it up in the current brand's config settings. The former being the case when a brand 
     * supports multiple vehicleTypes which should be localized depending on current circumstances (i. e. entered registration).
     * @param plural 
     * @param vehType 
     */
    getVehicleName(plural: boolean = false, vehType?: string): string {
        if (vehType) {
            let matchingKey: VEHICLES;
            for (const [key, value] of Array.from(VEHICLE_TYPE_MAP.entries())) {
                if (value.includes(vehType.toLowerCase())) {
                    matchingKey = key;
                    break;
                }
            }

            if (matchingKey) {
                return plural ? `${matchingKey}s` : matchingKey;
            }
        }
        return plural ? BRAND_CONFIGS[this.brand].vehicles : BRAND_CONFIGS[this.brand].vehicle;
    }
}
