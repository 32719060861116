export class Vehicle {

    constructor(
        // public vehicleReg: string,
        // public mileage: string,
        public basecolour: string,
        public canservice: string,
        public capcode: string,
        public dofr: string,
        public encregno: string,
        public fuel: string,
        public image: string,
        public make: string,
        public model: string,
        public specref: string,
        public vehTyp: string,
        public vehicle: string
    ) { }
}
