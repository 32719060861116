import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from '../models/address';

@Injectable()
export class PostcodeLookupService {
  constructor(private http: HttpClient) { }

    getAllAddresses(postcode) {
      return this.http.get('/content/api/json_solr.p?q=postsumm&pcode=' + postcode);
    }
}
