<p>
  Step 2 of 3 – Select your service option and extras if required
</p>

<fieldset class="form__fieldset" ngModelGroup="serviceGroup" #serviceGroup="ngModelGroup">

  <div class="row change-order--mobile-down">
    <div class="xs-col-12 m-col-9 order--2">
      <h4 class="heading" *ngIf="!formData.postcodeFound && !dealerSelected">We couldn't select a dealer for you
        automatically, please select a dealer before continuing.</h4>
      <ng-container *ngIf="(!formData.hasServicePlan && formData.servType !== '' && formData.Q658 !== 'Motability' && formData.Q658 !== 'Service Plan without MOT' && formData.Q658 !== 'Service Plan with MOT')
        || (formData.hasServicePlan && formData.servType !== ''
        && formData.dealerServiceControls?.locnDsc?.includes('Mercedes'))">
        <div class="form__group radio-btn-container" *ngIf="(formData.servType !== 'MOT'); else motTemplate">
          <h4 class="form__question-heading ">Service option</h4>
          <div class="radio-btn" *ngIf="formData.servAnnualInspectionCost !== '0.00' && formData.servAnnualInspectionCost !== ''">
            <label class="form__label line-height--small">{{ formData.servAnnualInspectionLbl }}
              <span class="form__sub-label font--bold">{{ formData.servAnnualInspectionCost | currency:'&pound;'
                }}</span>
              <input type="radio" name="serviceoption"
                (change)="formData.annualInspect = $event.target.checked ? 'ANNUALINSPECT' : ''; formData.intervalMonth = 0; reQuote();"
                [disabled]="!dealerSelected ? true : false" required />
              <span class="radio-btn__checkmark"></span>
            </label>
          </div>
          <div class="radio-btn" id="step2-{{interval.servmonths}}-months"
            *ngFor="let interval of formData.servIntervals; let i = index">
            <label class="form__label line-height--small" *ngIf="interval.servcosts !== ''">
              <ng-container *ngIf="interval.servmonths !== '0' && formData.servType; else poaTemplate">
                {{interval.servmonths}} months | {{interval.servmiles}} miles
                <ng-container *ngIf="interval.servdiscount !== '0.00' && getServiceDetailsService.hasDiscount">
                  <span class="form__sub-label margin-0 margin-top-5" style="display: block;">
                    <span style="text-decoration: line-through;">{{ interval.servcosts | currency:'&pound;' }}</span>
                    <span class="font--bold pill-nav__item margin-left-10"> {{ getServiceDetailsService.discount }}%
                      OFF</span>
                  </span>
                </ng-container>

                <span class="form__sub-label font--bold">{{ interval.servprices | currency:'&pound;'}}</span>
              </ng-container>
              <ng-template #poaTemplate>
                {{interval.servcosts}}
              </ng-template>
              <input type="radio" [value]="interval.servmonths !== '0' ? interval.servmonths : interval.servcosts"
                [(ngModel)]="interval.servmonths !== '0' ? formData.intervalMonth : formData.servcosts"
                name="serviceoption={{i}}"
                (change)="formData.annualInspect = ''; setIntervalSelected(interval.servmonths !== '0' ? interval.servmonths : interval.servcosts, interval.servmiles, interval.servcosts, interval.servtimes, (i+1));"
                [disabled]="!dealerSelected ? true : false" required />
              <span class="radio-btn__checkmark"></span>
            </label>
          </div>
          <!-- <div *ngIf="serviceOption.invalid && serviceOption.dirty">
                    Service option is required.
                </div>  -->
        </div><!-- end form group -->
      </ng-container>


     <ng-container >
      <div class="form__group radio-btn-container"  *ngIf="formData.seasonalCheck">

      <h4 class="form__question-heading ">Seasonal option</h4>

      <div class="radio-btn">
        <label class="form__label line-height--small" for="Bronze">Bronze Package
          <span class="form__sub-label font--bold">{{ '£' + formData.servSeasonalBronzePackPrice
          }}</span>
        <input id="Bronze" type="radio" class="radio-btn" value="Bronze"  name="seasonoption"
        (change)=" seasonalRadioBtn = $event.target.checked && formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected = $event.target.checked ? 'Yes' : '';
        formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected = 'no';
        formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected = 'no';
        reQuote();">
          <span class="radio-btn__checkmark"></span>
        </label>
      </div>

      <div class="radio-btn">
        <label class="form__label line-height--small" for="Silver">Silver Package
          <span class="form__sub-label font--bold">{{ '£' + formData.servSeasonalSilverSrvPrice
          }}</span>
          <input id="Silver" type="radio" class="radio-btn" value="Silver"  name="seasonoption"
          (change)="seasonalRadioBtn = $event.target.checked && formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected = $event.target.checked ? 'Yes' : '';
          formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected = 'no';
          formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected = 'no';
          reQuote();">
          <span class="radio-btn__checkmark"></span>
        </label>
      </div>

      <div class="radio-btn">
        <label class="form__label line-height--small" for="Gold">Gold Package
          <span class="form__sub-label font--bold">{{ '£' + formData.servSeasonalGoldPrice
          }}</span>
          <input id="Gold" type="radio" class="radio-btn" value="Gold"  name="seasonoption"
          (change)="seasonalRadioBtn = $event.target.checked && formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected = $event.target.checked ? 'Yes' : '';
          formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected = 'no';
          formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected = 'no';
          reQuote();">
          <span class="radio-btn__checkmark"></span>
        </label>
      </div>

     </div>
   </ng-container>
   <!-- Form ends -->

      <ng-template #motTemplate>
        <div class="radio-btn" id="step2-{{interval.servmonths}}-months" ng-init=""
          *ngFor="let interval of formData.servIntervals; let i = index">
          <label class="form__label line-height--small" *ngIf="interval.servmonths !== ''"
            ng-init="interval.servmonths='MOT'">
            {{interval.servmonths}}
            <span class="form__sub-label font--bold">{{interval.servcosts | currency:'&pound;'}}</span>
            <input type="radio" [value]="interval.servmonths" [(ngModel)]="formData.intervalMonth" name="serviceoption"
              (change)="setIntervalSelected(interval.servmonths, interval.servmiles, interval.servcosts, interval.servtimes, (i+1));"
              [disabled]="!dealerSelected ? true : false" required />
            <span class="radio-btn__checkmark"></span>
          </label>
        </div>
      </ng-template>

      <!-- <hr class="horizontal-separator"> -->
      <div class="section__divider"></div>

      <div class="form__group margin-bottom-10">
        <div class="row">
          <div class="checkbox-container xs-col-12 s-col-6 m-col-6 l-col-6"
            *ngIf="formData.servType === 'Service' || formData.servType === 'ServiceMOT' || formData.servType === 'MOT' || formData.generalRepMain === 'Yes'">

            <ng-container *ngIf="formData.dealerServiceControls">
              <h4 class="form__question-heading">{{ !isVauxhall ? 'Extras' : 'Transport Assistance' }}</h4>
              <ng-container
              *ngIf="(formData.servType === 'Service' || formData.servType === 'ServiceMOT') || formData.generalRepMain !== 'Yes'">
                <div class="checkbox" id="step2-mot"
                 *ngIf="formData.servType !== 'MOT'">
                  <label class="form__label">MOT
                    <span *ngIf="(formData.Q658 !== 'Service Plan with MOT' && formData.Q658 !== 'Motability'
                    || serviceDetails?.dealerServiceControls?.locnDsc?.includes('Mercedes'))"
                    class="form__sub-label form__label--inline font--bold">(+
                      {{serviceDetails.servOptionCosts?.servMOTPrice | currency:'&pound;'}})</span>
                    <input type="checkbox" name="servType"
                      [checked]="formData.servType === 'ServiceMOT' || formData.servType === 'MOT'"
                      (change)="formData.servType = $event.target.checked ? (formData.servType === 'Service' ? 'ServiceMOT' : 'MOT') : (formData.servType === 'ServiceMOT' ? 'Service' : ''); reQuoteService()"
                      [disabled]="!dealerSelected ? true : false" />
                    <span class="checkbox__checkmark"></span>
                  </label>
                </div>
                <!-- code for courtesy checkbox -->
                <ng-container
                  *ngIf="(formData.dealerServiceControls?.locnCCPrice && serviceDetails.dealerCourtesyAvailable === 'yes')">
                  <div class="checkbox" [ngClass]="{'checkbox-disabled': keyDropOffChecked || collectionAndDeliveryChecked}">
                    <label class="form__label" id="step2-courtesy">Courtesy {{ brandsConfigService.getVehicleName(false,
                      formData.vehTyp) | titlecase }}
                      <span class="form__sub-label form__label--inline font--bold">(+
                        {{formData.dealerServiceControls?.locnCCPrice | currency:'&pound;' }})</span>
                      <input type="checkbox" name="is-courtesy-car" [checked]="formData.courtesyCar === 'COURTESYCAR'"
                        (change)="formData.courtesyCar = $event.target.checked ? 'COURTESYCAR' : ''; formData.collectNdeliver = ''; formData.whileUwait = ''; reQuoteService()"
                        (change)="courtesyChecked = $event.target.checked ? true : false" [disabled]="keyDropOffChecked || collectionAndDeliveryChecked" />
                      <span class="checkbox__checkmark"></span>
                    </label>
                  </div>
                  <ng-container *ngIf="formData.courtesyCar === 'COURTESYCAR'">
                    <div class="courtesy-validation__container">
                      <div class="courtesy-validation">
                        <ul><h5>For a courtesy car you must be:</h5>
                          <li class="courtesy-validation__list-item"><i class="fal fa-check icon-fa--small icon-fa--margin-right text--primary" aria-hidden="true"></i> Between the age of 21 - 85</li>
                          <li class="courtesy-validation__list-item"><i class="fal fa-check icon-fa--small icon-fa--margin-right text--primary" aria-hidden="true"></i> Held licence for a minimum of 1 year</li>
                          <li class="courtesy-validation__list-item"><i class="fal fa-check icon-fa--small icon-fa--margin-right text--primary" aria-hidden="true"></i> No more than 9 points in the last 3 years</li>
                          <li class="courtesy-validation__list-item"><i class="fal fa-check icon-fa--small icon-fa--margin-right text--primary" aria-hidden="true"></i> No disqualifications in the last 5 years</li>
                        </ul>
                      </div>
                      <div class="courtesy-validation__info">
                        <i class="fal fa-info-circle icon-fa--small icon-fa--margin-left"></i><p>If you're under 21 you must have held your licence for a minimum of 1 year and have no points.</p>
                      </div>
                    </div>
                    <div class="checkbox courtesy-validation__confirmation" id="courtesyConfirmation">
                      <label class="form__label">
                        I confirm that I follow the above criteria
                        <input type="checkbox" name="courtesyConfirmation" [checked]="courtesyConfirmed"
                        (change)="courtesyConfirmed = $event.target.checked ? true : false">
                        <span class="checkbox__checkmark margin-right-5"></span>
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
                <!-- courtesy checkbox code ends here - if removed, checkbox disappears -->
              </ng-container>
              <ng-container *ngIf="formData.VcTechOptStsCde !== 1">
                <div *ngFor="let option of formData.servTechOptions">
                  <ng-container *ngIf="option.servTechAvailable === 'yes'">
                    <div class="checkbox">
                      <label class="form__label" id="step2-extraoption">{{ option.servTechLbl }}
                        <span class="form__sub-label form__label--inline font--bold">(+
                          {{option.servTechCost | currency:'&pound;' }})</span>
                        <input type="checkbox" name="is-extra-option"
                          [checked]="formData['techopt' + option.servTechId] === 'yes'"
                          (change)="formData['techopt' + option.servTechId] = $event.target.checked ? 'yes' : ''; reQuoteService()" />
                        <span class="checkbox__checkmark"></span>
                      </label>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <div class="checkbox" id="step2-collection" *ngIf="formData.dealerServiceControls.locnCDSts === ''"
              [ngClass]="{'checkbox-disabled': courtesyChecked || keyDropOffChecked}">
              <label class="form__label">Collection & Delivery
                <span *ngIf="formData.dealerServiceControls?.locnCDPrice && ((formData.servType === 'MOT' && formData.Q658 == 'Service Plan without MOT') || formData.Q658 == 'No'  && serviceDetails.hasServicePlanWithMOT == 'no' || formData?.dealerServiceControls?.locnDsc?.includes('Mercedes'))"
                  class="form__sub-label form__label--inline font--bold">(+
                  {{formData.dealerServiceControls?.locnCDPrice | currency:'&pound;' }})</span>
                <input type="checkbox" id="collection" name="is-collection-delivery"
                  [checked]="formData.collectNdeliver === 'COLLECTANDDELIVER'"
                  (change)="formData.collectNdeliver = $event.target.checked ? 'COLLECTANDDELIVER' : ''; formData.courtesyCar = ''; formData.whileUwait = ''; reQuoteService()"
                  (change)="collectionAndDeliveryChecked = $event.target.checked ? true : false"
                  [disabled]="courtesyChecked || keyDropOffChecked"/>
                <span class="checkbox__checkmark"></span>
              </label>
            </div>
              <div class="checkbox"
                *ngIf="serviceDetails.dealerServiceControls?.locnAllowsWait !== 'no' || formData.whileUwait === 'WHILEUWAIT'">
                <label class="form__label" id="step2-waiting">Waiting Appointment
                  <input type="checkbox" name="is-waiting-app" [checked]="formData.whileUwait === 'WHILEUWAIT'"
                    (change)="formData.whileUwait = $event.target.checked ? 'WHILEUWAIT' : ''; formData.courtesyCar = ''; formData.collectNdeliver = ''; reQuoteService()"
                    [disabled]="!dealerSelected ? true : false" />
                  <span class="checkbox__checkmark"></span>
                </label>
              </div>

              <div class="checkbox" [ngClass]="{'checkbox-disabled': courtesyChecked || collectionAndDeliveryChecked}"
              *ngIf="serviceDetails?.dealerServiceControls?.locnAllowsKeyDrop && serviceDetails?.dealerServiceControls?.locnAllowsKeyDrop !== 'no'">
                <label class="form__label" id="step2-waiting">Key drop off
                  <input type="checkbox" name="is-waiting-app" [checked]="formData.keyDropOff === 'KEYDROPOFF'"
                    (change)="formData.keyDropOff = $event.target.checked ? 'KEYDROPOFF' : ''; reQuoteService()"
                    (change)="keyDropOffChecked = $event.target.checked ? true : false"
                    [disabled]="!dealerSelected ? true : false || courtesyChecked || collectionAndDeliveryChecked" />
                  <span class="checkbox__checkmark"></span>
                </label>
              </div>

            </ng-container>
          </div><!-- end form group -->

          <div class="xs-col-12 s-col-6 m-col-6 l-col-6">
            <h4>Select your service date</h4>

            <mat-form-field class="example-full-width"
              [class.hide__default]="formData.calendarStartDate === null || displayAlert">
              <input name="Q99" class="date-pick" matInput [matDatepickerFilter]="availableDates"
                [matDatepicker]="picker" autocomplete="off" (dateChange)="setDateSelected(); selectedTime = null"
                [(ngModel)]="selectedDate" placeholder="Select preferred date"
                [disabled]="!dealerSelected ? true : false" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="month" [startAt]="formData.calendarStartDate"></mat-datepicker>
            </mat-form-field>

            <div class="alert-box icon__info alert--error margin-0" style="max-width: 430px;"
              *ngIf="formData.calendarStartDate === null || displayAlert">
              <p *ngIf="formData.dealerServiceControls">
                Sorry, there are no available dates for the selected extra option. Please try a different option or
                contact us from our <a class="body-link--underline" href="/contact-us/">contact page</a>.
              </p>
              <p *ngIf="!formData.dealerServiceControls">
                Sorry, there are no available dates for your vehicle. Please
                contact us from our <a class="body-link--underline" href="/contact-us/">contact page</a>.
              </p>
            </div>

            <div
              *ngIf="selectedDate && formData.whileUwait === 'WHILEUWAIT' || selectedDate && formData.advisorAppointmentSts === 'ADVISOR_REQUIRED' && !collectionAndDeliveryChecked"
              class="form__element form__element--small">
              <h4>Select a time to drop off your vehicle</h4>
              <select
                (change)="setTimeSelected(); (selectedDate && (formData.whileUwait === 'WhileUWait') && (selectedTime !== ''))? dateValid=true : dateValid = false"
                [(ngModel)]="selectedTime" name="selectedTime" required>
                <option value="" disabled>Select preferred time slot</option>
                <option *ngFor="let time of availableTimes" [ngValue]="time.bkTime">{{time.bkTime}}</option>
              </select>
              <p *ngIf="formData.advisorAppointmentSts === 'ADVISOR_REQUIRED'" class="text-size--small padding-top-10">
                This is the drop-off time only, and not the time we will be servicing your vehicle.</p>
            </div>
            <div *ngIf="selectedDate && collectionAndDeliveryChecked">
              <h4>Collection of your vehicle will be made between 8am - midday</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- end form group -->

      <div class="section__divider"></div>
      <!-- <hr class="horizontal-separator"> -->

      <div class="form__group">
        <div class="row">
          <div class="xs-col-12 s-col-6 m-col-4">
            <ng-container *ngIf="formData.dealerServiceControls">

              <h4 class="heading--grouped" *ngIf="formData.postcodeFound">We've selected your nearest dealership for
                you:</h4>
              <h4 class="heading--group" *ngIf="!formData.postcodeFound">{{ changeLocation ? 'Please select a dealership:' :
                'Selected dealership:'}}</h4>
              <address class="form__address" *ngIf="formData.dealerServiceControls.locnAddress && !changeLocation">
                {{formData.dealerServiceControls.locnDsc }}<br>
                {{formData.dealerServiceControls.locnAddress.locnAddr1}}<br>
                {{formData.dealerServiceControls.locnAddress.locnAddr2}}<br>
                {{formData.dealerServiceControls.locnAddress.locnTown}},
                {{formData.dealerServiceControls.locnAddress.locnPostcode}}<br>
              </address>
              <p class="text-size--medium" *ngIf="formData.postcodeFound"><i
                  class="fas fa-map-marker-alt text--light-grey" aria-hidden="true"></i>
                {{formData.dealerServiceControls.locnDistance}} from you</p>
              <p><a class="body-link font--bold" (click)="changeLocationToggle()" *ngIf="!changeLocation">Change
                  location</a></p>
              <select *ngIf="changeLocation && formData.locations" name="selectedLocation"
                [(ngModel)]="formData.location" (change)="reQuoteLocation()">
                <option selected disabled>Select preferred location</option>
                <option *ngFor="let dealership of formData.locations">{{dealership}}</option>
              </select>

            </ng-container>
          </div>

          <div class="xs-col-12 s-col-6 m-col-7">
            <div class="">
              <h4 class="form__question-heading">Additional Comments</h4>
              <textarea cols="60" class="" name="comments" [(ngModel)]="formData.comments"
                placeholder="{{ formData.recall === '' ? 'Anything specific that you would like us to look at while your ' + brandsConfigService.getVehicleName(false, formData.vehTyp) + ' is with us? ' : '' }}{{
                formData.tyreCheck === 'Yes' ? 'For example do you need replacement tyres? Please make sure you bring your locking wheel nut key along for your appointment. ' : '' }}{{
                formData.recall === 'Yes' ? 'Please provide as much information as possible about the manufacturer recall. ' : '' }}{{
                formData.generalRepMain === 'Yes' ? 'Please provide as much information as possible about the repairs needed. ' : '' }}">
            </textarea>
            </div>

            <div class="row margin-top-10" style="justify-content: space-between;">

              <div class="xs-col-12 l-col-6">
                <app-total-costs [formData]="formData"
                  [displayServicePlanMessage]="getServiceDetailsService.displayServicePlanMessage$ | async"
                  [moreOptionsSelected]="getServiceDetailsService.moreOptionsSelected$ | async"
                  [hasDiscount]="getServiceDetailsService.hasDiscount"
                  [totalDiscount]="getServiceDetailsService.totalDiscount"
                  [serviceResponse]="getServiceDetailsService.serviceResponse">
                </app-total-costs>
              </div>

              <div class="xs-col-12 l-col-6">
                <div class="form__element-wrapper form__message--hover">
                  <button id="step2-continue" class="btn btn--full-width btn--primary form__btn"
                    [attr.disabled]="serviceGroup.invalid || !dealerSelected || (dateRequired && !dateValid) ? true : null || checkSeasonalOption() ||checkCourtesyConfirmation()"
                    matStepperNext type="button" (click)="scrollToTop(); updateDataLayer();">Continue</button>
                  <div class="form__message form__message--error" *ngIf="serviceGroup.invalid">Please complete all
                    fields</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="xs-col-12 m-col-3 order--1" *ngIf="vehicle">
      <aside class="form__summary tile__column tile--with-border-radius background--white">
        <div class="summary__img tile-img__container hide--tablet-down">
          <img [imgSrc]="formData?.image" [alt]="formData?.vehicle" appImgLoader>
        </div>
        <div class="tile-body form__vehicle-details">
          <h4 class="heading--grouped">
            <span *ngIf="formData.servType">{{formData.servType === 'ServiceMOT' ? 'Service & MOT' : formData.servType}}
              <span
                *ngIf="formData.intervalMonth && !formData.isTextBased && !formData.isMOT">({{formData.intervalMonth}}
                months)</span>
              <span *ngIf="formData.intervalMonth && formData.isTextBased">({{formData.intervalMonth}})</span><br>
              <span *ngIf="formData.annualInspect">({{formData.servAnnualInspectionLbl}})</span><br></span>
            <span *ngIf="formData.seasonalCheck === 'Yes'">Seasonal Check<span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalBronzeSelected | titlecase)  ===  'Yes'"> - Bronze</span>
            <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalSilverSelected| titlecase)  ===  'Yes'"> - Silver</span>
            <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalGoldSelected| titlecase)  ===  'Yes'"> - Gold</span></span>



            <span *ngIf="formData.tyreCheck === 'Yes'">Tyre check<br></span>
            <span *ngIf="formData.generalRepMain === 'Yes'">General Repair and Maintenance<br></span>
            <span *ngIf="formData.recall === 'Yes'">Recall<br></span>
            <span *ngIf="formData.healthCheck === 'Yes'">Health check<br></span>
          </h4>
          <h4>{{formData?.vehicle}}</h4>
          <p class="tile-text--grouped text--medium-grey text-size--medium"><span
              class="text--uppercase">{{formData.vehicleReg}}</span> |
            {{formData.mileage}}
            miles | {{formData?.basecolour}} | {{formData?.fuel}}</p>
          <p class="text-size--medium">First registered: {{formData?.dofr}}</p>
          <button class="body-link text-size--medium font--bold" matStepperPrevious type="button">Not your {{
            brandsConfigService.getVehicleName(false, formData.vehTyp) }}?</button>
        </div>
      </aside>
    </div>
  </div>
</fieldset>
