import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '../models/vehicle';
import { Observable } from 'rxjs';

declare var unique_ref_c: string;
declare var formRef: string;

@Injectable({
  providedIn: 'root'
})

export class VehicleLookupService {
  
  constructor(private http: HttpClient) { }

  findVehicle(registration): Observable<any> {
    return this.http.get('/content/api/json_veh.p?regno=' + registration + '&formref=' + formRef + '&uniquerefc=' + unique_ref_c);
  }
}
