import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormData } from '../models/formData';
import { Vehicle } from '../models/vehicle';
import { Location } from '../models/location';
import { map } from 'rxjs/operators';

/**
 * Convert the provided number to a rounded number with 2 fixed decimals
 * @param num
 */
const toFixedDecimals = (num: number) => parseFloat((Math.round(num * 100) / 100).toFixed(2));

@Injectable({
  providedIn: 'root'
})
export class GetServiceDetailsService {

  /**
   * Store if the service plan message should be displayed as an observable
   */
  private _displayServicePlanMessage$: BehaviorSubject<boolean>;
  displayServicePlanMessage$: Observable<boolean>;

  get displayServicePlanMessage(): boolean {
    return this._displayServicePlanMessage$.value;
  }

  set displayServicePlanMessage(val: boolean) {
    this._displayServicePlanMessage$.next(val);
  }
    /**
   * Store if the more options selected should be displayed as an observable
   */

  private _moreOptionsSelected$: BehaviorSubject<boolean>;
  moreOptionsSelected$: Observable<boolean>;

  get moreOptionsSelected(): boolean {
    return this._moreOptionsSelected$.value;
  }

  set moreOptionsSelected(val: boolean) {
    this._moreOptionsSelected$.next(val);
  }

  // If it should apply a discount to the total price
  private _hasDiscount: boolean;

  get hasDiscount() {
    return this._hasDiscount;
  }

  private _discount = 0;

  get discount() {
    return toFixedDecimals(this._discount * 100);
  }

  // Total discount percentage to be displayed on total price
  private _totalDiscount: number;

  get totalDiscount() {
    return toFixedDecimals(this._totalDiscount * 100);
  }

  private _serviceResponse: any;

  get serviceResponse() {
    return this._serviceResponse;
  }

  constructor(private http: HttpClient) {
    this._applyDiscountMap = this._applyDiscountMap.bind(this);

    this._displayServicePlanMessage$ = new BehaviorSubject(false);
    this.displayServicePlanMessage$ = this._displayServicePlanMessage$.asObservable();

    this._moreOptionsSelected$ = new BehaviorSubject(false);
    this.moreOptionsSelected$ = this._moreOptionsSelected$.asObservable();
  }

  /**
   * Apply the discount to the map observable as a callback
   * @param res The http response
   */
  private _applyDiscountMap(res) {
    try {
      this._hasDiscount = res.servOptionCosts.servDiscountPercent !== '';
    } catch (error) {
      console.error(error);
      this._hasDiscount = false;
    }

    this._discount = this._hasDiscount ? parseFloat(res.servOptionCosts.servDiscountPercent) / 100 : 0;

    // Discount on total cost
    if (res && res.servOptionCosts && res.servOptionCosts.servTotalServiceCost && res.servOptionCosts.servServiceDiscount) {
      if (
        !/[a-z]/ig.test(res.servOptionCosts.servTotalServiceCost)
        && res.servOptionCosts.servTotalServiceCost !== ''
        && res.servOptionCosts.servServiceDiscount !== ''
        && res.servOptionCosts.servSelectedService !== '0'
      ) {
        const totalPriceNum = parseFloat(res.servOptionCosts.servTotalServiceCost);
        const serviceDiscountNum = parseFloat(res.servOptionCosts.servServiceDiscount);

        this._totalDiscount = toFixedDecimals(serviceDiscountNum / (totalPriceNum + serviceDiscountNum));

        // Store the original price to the response to display the discount
        res.servOptionCosts.originServTotalServiceCost = '' + (totalPriceNum + serviceDiscountNum);
      } else {
        // Delete the original service total cost if for some reason the response change to be on the safe side
        delete res.servOptionCosts.originServTotalServiceCost;
      }
    }

    this._serviceResponse = res;

    return res;
  }

  getServiceDetails(vehicle: Vehicle, location: Location, selectedDate, formData: FormData): Observable<any> {
    // The service plan message should only be displayed when the user has only selected Service
    // https://app.asana.com/0/751270265214859/1198888385952981/f
    if (formData.hasServicePlan) {
      const hasSelectedExtraOption = [
        formData.tyreCheck,
        formData.seasonalCheck,
        formData.healthCheck,
        formData.generalRepMain,
        formData.recall
      ].some(option => option === 'Yes');

      this.moreOptionsSelected = hasSelectedExtraOption;
      // this.displayServicePlanMessage = formData.servType === 'Service' && !hasSelectedExtraOption;
    }

    return this.http.get<any>('/content/api/json_splanquote.p',
      {
        params: {
          vehspec: formData.specref,
          mileage: formData.mileage,
          dofr: formData.dofr,
          location: formData.location,
          servtype: formData.servType,
          seasonalCheck: formData.seasonalCheck,
          tyreCheck: formData.tyreCheck,
          adBlue: formData.adBlue,
          generalRepMain: formData.generalRepMain,
          recall: formData.recall,
          selectedService: formData.selectedService,
          courtesy: formData.courtesyCar,
          whileUwait: formData.whileUwait,
          collectNdeliver: formData.collectNdeliver,
          keyDropOff: formData.keyDropOff || '',
          regno: formData.vehicleReg,
          make: formData.make,
          model: formData.model,
          fuel: formData.fuel,
          postcode: formData.postcode,
          dateSelected: selectedDate,
          annualInspect: formData.annualInspect,
          healthCheck: formData.healthCheck,
          advisorAppointmentSts: formData.advisorAppointmentSts,
          techoptECS: formData.techoptECS,
          techoptACS: formData.techoptACS,
          techoptBFC: formData.techoptBFC,
          techoptCoolant: formData.techoptCoolant,
          techoptPollen: formData.techoptPollen,
          techoptGearbox: formData.techoptGearbox,
          techoptCambelt: formData.techoptCambelt,
          Q658: formData.Q658,
          seasonalDetox: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalDetoxSelected : '',
          seasonalAirConSrv: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalAirConSelected : '',
          seasonalCombined: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalCombinedSelected : '',
          seasonalBronze: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected : '',
          seasonalSilver: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected : '',
          seasonalGold: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected : '',
        }
      }).pipe(map(this._applyDiscountMap));

  }

  getServiceDetailsWithoutDate(vehicle: Vehicle, location: Location, formData: FormData): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get('/content/api/json_splanquote.p',
      {
        params: {
          vehspec: formData.specref,
          mileage: formData.mileage,
          dofr: formData.dofr,
          location: formData.location,
          servtype: formData.servType,
          seasonalCheck: formData.seasonalCheck,
          tyreCheck: formData.tyreCheck,
          adBlue: formData.adBlue,
          generalRepMain: formData.generalRepMain,
          recall: formData.recall,
          selectedService: formData.selectedService,
          courtesy: formData.courtesyCar,
          whileUwait: formData.whileUwait,
          collectNdeliver: formData.collectNdeliver,
          regno: formData.vehicleReg,
          make: formData.make,
          model: formData.model,
          fuel: formData.fuel,
          postcode: formData.postcode,
          dateSelected: formData.selectedDate,
          annualInspect: formData.annualInspect,
          healthCheck: formData.healthCheck,
          techoptECS: formData.techoptECS,
          techoptACS: formData.techoptACS,
          techoptBFC: formData.techoptBFC,
          techoptCoolant: formData.techoptCoolant,
          techoptPollen: formData.techoptPollen,
          techoptGearbox: formData.techoptGearbox,
          techoptCambelt: formData.techoptCambelt,
          Q658: formData.Q658,
          seasonalDetox: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalDetoxSelected : '',
          seasonalAirConSrv: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalAirConSelected : '',
          seasonalCombined: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalCombinedSelected : '',
          seasonalBronze: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected : '',
          seasonalSilver: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected : '',
          seasonalGold: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected : '',
        }
      }).pipe(map(this._applyDiscountMap));
  }
}
