import { Component, Input } from '@angular/core';
import { FormData } from 'src/app/models/formData';

@Component({
  selector: 'app-total-costs',
  templateUrl: './total-costs.component.html',
  styleUrls: ['./total-costs.component.scss']
})
export class TotalCostsComponent {

  /**
   * If the template should apply heading classes or not
   */
  @Input() hasHeadings = true;

  @Input() formData: FormData;

  @Input() displayServicePlanMessage: boolean;

  @Input() moreOptionsSelected: boolean;

  @Input() hasDiscount: boolean;

  @Input() totalDiscount: number;

  @Input() serviceResponse: any;

  constructor() { }
}
