const DATE_REGEX: RegExp = 
new RegExp("((((0[1-9])|([1-2][0-9])|30)\/(04|06|09|11))|(((0[1-9])|([1-2][0-9])|30|31)\/(01|03|05|07|08|10|12))|(((0[1-9])|([1-2][0-9]))\/02))\/([2-9][0-9]{3})");
// new RegExp("(THIRTY|THIRTY_ONE|FEB)\/YEAR");
const THIRTY: string = "(((0[1-9])|([1-2][0-9])|30)\/(04|06|09|11))";
const THIRTY_ONE: string = "(((0[1-9])|([1-2][0-9])|30|31)\/(01|03|05|07|08|10|12))";
const FEB: string = "(((0[1-9])|([1-2][0-9]))\/02)";
const YEAR: string = "([2-9][0-9]{3})";

export default class DateChecker {
    static checkDate(date: string) : boolean {
        return DATE_REGEX.test(date);
    }
}