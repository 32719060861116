import { Directive, ElementRef, Input } from '@angular/core';
import { BrandsConfigService } from '../services/brands-config.service';

@Directive({
  selector: '[appImgLoader]'
})
export class ImgLoaderDirective {
  // The default image source when an error occurs
  private _errSrc = this.brandsConfigService.getConfigValue('myVehicleFallbackImg');

  @Input()
  set imgSrc(src: string) {
    // If the src has commas then separate it and get the first element
    if (/,/.test(src)) {
      const srcArray = src.split(',');
      [ src ] = srcArray;
    }

    // Set the image source and add an error handler
    this._elRef.nativeElement.src = src;
    this._elRef.nativeElement.addEventListener('error', this._handleError);
  }

  @Input()
  set errorSrc(errSrc: string) {
    this._errSrc = errSrc;
  }

  constructor(
    private _elRef: ElementRef<HTMLImageElement>,
    private brandsConfigService: BrandsConfigService
  ) {
    // Bind the error handler to the class
    this._handleError = this._handleError.bind(this);
  }

  /**
   * Handle the image error to load the error source
   */
  private _handleError() {
    this._elRef.nativeElement.src = this._errSrc;
    this._elRef.nativeElement.removeEventListener('error', this._handleError);
  }
}
