<p>
  Step 1 of 3 – Please enter your vehicle registration, mileage (approximate will do), and your postcode
</p>

<fieldset class="form__fieldset" ngModelGroup="vehicleGroup" #vehicleGroup="ngModelGroup" name="services-field">

  <div class="form__group reduced-margin-form checkbox-container" *ngIf="formData">
    <h4 class="form__question-heading">Which type of service would you like to book?</h4>

    <div class="row row--no-margin">
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox">
          <label class="form__label" id="step1-service">Service
            <input id="service" type="checkbox" name="serv" [checked]="formData.servType === 'Service'"
              (change)="formData.servType = $event.target.checked ? 'Service' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox" id="step1-mot">
          <label class="form__label">MOT
            <input id="mot" type="checkbox" name="serv" [checked]="formData.servType === 'MOT'"
              (change)="formData.servType = $event.target.checked ? 'MOT' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox" id="step1-service-mot">
          <label class="form__label">Service & MOT
            <input id="servicemot" type="checkbox" name="serv" [checked]="formData.servType === 'ServiceMOT'"
              (change)="formData.servType = $event.target.checked ? 'ServiceMOT' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox" id="step1-seasonal-check">
          <label class="form__label">Seasonal Check
            <input type="checkbox" name="seasonalCheck" [checked]="formData.seasonalCheck === 'Yes'"
              (change)="formData.seasonalCheck = $event.target.checked ? 'Yes' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox" id="step1-tyre-check">
          <label class="form__label">Tyre Check
            <input id="tyre-check" type="checkbox" name="tyreCheck" [checked]="formData.tyreCheck === 'Yes'"
              (change)="formData.tyreCheck = $event.target.checked ? 'Yes' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox">
          <label class="form__label" id="step1-maintenance">General Repair & Maintenance
            <input type="checkbox" name="generalRepMain" [checked]="formData.generalRepMain === 'Yes'"
              (change)="formData.generalRepMain = $event.target.checked ? 'Yes' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox">
          <label class="form__label" id="step1-recall">Recall
            <input type="checkbox" name="recall" [checked]="formData.recall === 'Yes'"
              (change)="formData.recall = $event.target.checked ? 'Yes' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="xs-col-12 s-col-6 m-col-3">
        <div class="checkbox">
          <label class="form__label" id="step1-recall">Health Check
            <input type="checkbox" name="healthCheck" [checked]="formData.healthCheck === 'Yes'"
              (change)="formData.healthCheck = $event.target.checked ? 'Yes' : ''; onServTypeChanged();" />
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <div class="form__group reduced-margin-form checkbox-container" *ngIf="formData && !isTaxiCentre">
      <h4 class="form__question-heading">Are you either of the following customers?</h4>

      <div class="row row--no-margin">
        <div class="xs-col-12 s-col-6 m-col-3">
          <div class="checkbox">
            <label class="form__label" id="step1-splanmotCustomer">Service plan
              <input type="radio" name="splanmotCustomer" [checked]="selfCheckedSplan"
              (change)="selfCheckedSplan = $event.target.checked ? true : false"
              (change)="formData.Q658 = $event.target.checked ? 'Service Plan without MOT' : 'No'"/>
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>

        <div class="xs-col-12 s-col-6 m-col-3">
          <div class="checkbox">
            <label class="form__label" *ngIf="!brandsConfigService.isHidden('motabilityOptions')">Motability customer
              <input type="radio" name="splanmotCustomer" [checked]="motabilityPlan"
              (change)="selfCheckedSplan = $event.target.checked ? false : true"
              (change)="formData.Q658 = $event.target.checked ? 'Motability' : ''"/>
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>

        <div class="xs-col-12 s-col-6 m-col-3">
          <div class="checkbox">
            <label class="form__label" id="step1-splanmotCustomer">Neither
              <input type="radio" name="splanmotCustomer" [checked]="notMotabNotSplan"
                (change)="selfCheckedSplan = $event.target.checked ? false : true"
                (change)="formData.Q658 = $event.target.checked ? 'No' : ''"/>
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>


        <ng-container *ngIf="selfCheckedSplan">
          <p>Please ensure your Service Plan can be used at the dealership you will select for your visit. If it is a manufacturer's Service Plan, it must go to a dealership
            of the same franchise as your vehicle</p>
        </ng-container>
      </div>
    </div>

    <div class="form__group reduced-margin-form checkbox-container" *ngIf="formData && selfCheckedSplan">
      <h4 class="form__question-heading">Does your service plan include MOT?</h4>

      <div class="row row--no-margin">
        <div class="xs-col-3">
          <div class="checkbox">
            <label class="form__label">Yes
              <input type="radio" name="yesnomotab" [checked]="selfCheckedMOTYes"
              value="Yes"
              (change)="selfCheckedMOTNO = $event.target.checked ? false : true"
              (change)="selfCheckedMOTYes = $event.target.checked ? true : false"
              (change)="formData.Q658 = $event.target.checked ? 'Service Plan with MOT' : ''"/>
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>

        <div class="xs-col-3">
          <div class="checkbox">
            <label class="form__label">No
              <input type="radio" name="yesnomotab"
              value="No"
              (change)="selfCheckedMOTYes = $event.target.checked ? false : true"
              (change)="selfCheckedMOTNO = $event.target.checked ? true : false"
              (change)="formData.Q658 = $event.target.checked ? 'Service Plan without MOT' : ''"/>
              <span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="form__message form__message--error" *ngIf="!servTypeSelected">
      You must select at least one service
    </div>

  </div><!-- end form group -->

  <div class="section--x-small">
    <p>To start your booking, please enter your registration, mileage & postcode. We'll recommend branches based on
      your location. </p>
  </div>
  <div class="row">
    <div class="xs-col-12 s-col-6 m-col-3">
      <div class="form__vehicle-reg-input">
        <input name="Q57" type="text" id="Q57" #vehicleReg="ngModel" placeholder="YOUR REG" name="Q57"
          [(ngModel)]="formData.vehicleReg" (ngModelChange)="onRegistrationChange($event)" required />
        <!-- Error messages -->
        <ng-container *ngIf="vehicleReg.invalid && (vehicleReg.dirty || vehicleReg.touched); else checkRegFound">
          <div class="form__message form__message--error" *ngIf="vehicleReg.errors.required">Vehicle
            Registration is required
          </div>
        </ng-container>
        <ng-template #checkRegFound>
          <ng-container
            *ngIf="vehicleFound==false && !vehicleBeingFetched && (vehicleReg.dirty || vehicleReg.touched) && vehicleReg.value!=''; else checkServicedVehType">
            <div class="form__message form__message--error">
              Vehicle Registration not found
            </div>
          </ng-container>
        </ng-template>
        <ng-template #checkServicedVehType>
          <ng-container
          *ngIf="!isServicedVehicleType && !vehicleBeingFetched && (vehicleReg.dirty || vehicleReg.touched) && vehicleReg.value!=''; else checkVehicleModel">
            <div class="form__message form__message--error">
              Vehicle type not supported
            </div>
          </ng-container>
        </ng-template>
        <ng-template #checkVehicleModel>
          <ng-container
            *ngIf="isTaxiCentre && !vehicleAllowed && (vehicleReg.dirty || vehicleReg.touched) && vehicleReg.value!=''">
            <div class="form__message form__message--error">
              Sorry, we are unable to service this model
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <div class="xs-col-12 s-col-6 m-col-3">
      <div class="form__element-wrapper">
        <input name="Q72" type="number" min="0" oninput="validity.valid||(value='')" #vehicleMileage="ngModel"
          placeholder="Mileage" [(ngModel)]="formData.mileage" required />
        <div class="form__message form__message--error" *ngIf="vehicleMileage.invalid && vehicleMileage.dirty">
          Vehicle Mileage is required
        </div>
      </div>
    </div>
    <div class="xs-col-12 s-col-6 m-col-3">
      <div class="form__element-wrapper">
        <input name="Q9" type="text" #personalPostcode="ngModel" id="Q9" placeholder="Postcode"
          [(ngModel)]="postcodeModel" (ngModelChange)="onPostcodeChange($event)" required />
        <div *ngIf="personalPostcode.invalid && (personalPostcode.dirty || personalPostcode.touched)">
          <div class="form__message form__message--error" *ngIf="personalPostcode.errors.required">
            Postcode is required
          </div>
        </div>
        <div class="form__message form__message--error"
          *ngIf="!isValidPostCode && !postCodeBeingFetched && (personalPostcode.dirty || personalPostcode.touched) && formData.postcode !== ''">
          Postcode not found
        </div>
      </div>
    </div>
    <div class="xs-col-12 s-col-6 m-col-3">
      <div class="form__element-wrapper form__message--hover">
        <button id="step1-continue" matStepperNext type="button" class="btn btn--primary btn--full-width"
          (click)="getServiceQuote();updateDataLayer();"
          [disabled]="vehicleGroup.invalid || !vehicleFound || !vehicleAllowed || this.postCodeBeingFetched || !servTypeSelected || !isValidPostCode || !isServicedVehicleType">
          Start my booking
        </button>
        <div class="form__message form__message--error" *ngIf="vehicleGroup.invalid">
          Please complete all fields
        </div>
        <div class="form__message form__message--error" *ngIf="vehicleGroup.valid && (vehicleFound==false)">
          Please check all fields are correct</div>
      </div>
    </div>
    <!-- <div class="xs-col-12">
      <div class="alert-box icon__info alert--error margin-0" style="max-width: 430px;"
        *ngIf="!vehicleAllowed && vehicleFound">
        <p>
          Sorry, there are no available dates for your vehicle. Please
          contact us from our <a class="body-link--underline" href="/contact-us/">contact page</a>.
        </p>
      </div>
    </div> -->
  </div>

</fieldset>