export class FormData {
  constructor(
    public vehicleReg: string,
    public mileage: string,
    public postcode: string,
    public postcodeFound: boolean = false,
    public basecolour: string,
    public canservice: string,
    public capcode: string,
    public dofr: string,
    public encregno: string,
    public fuel: string,
    public image: string,
    public make: string,
    public model: string,
    public specref: string,
    public vehTyp: string,
    public vehicle: string,
    public servIntervals: Array<any>,
    public dealerServiceControls: any,
    public locations: Array<any>,
    public locnimage: string,
    private _location: string,
    public dealerAvailability: Array<any>,
    public calendarStartDate: Date,
    public selectedDate: string,
    public selectedTime: string,
    public isMOT: boolean,
    public servType: string,
    public servOptionCosts: any,
    public servAnnualInspectionLbl: string,
    public servAnnualInspectionCost: string,
    public servTechOptionSts: string,
    public servTechOptions: Array<any>,
    public techoptECS: string,
    public techoptACS: string,
    public techoptBFC: string,
    public techoptCoolant: string,
    public techoptPollen: string,
    public techoptGearbox: string,
    public techoptCambelt: string,
    public annualInspect: string,
    public seasonalCheck: string,
    public tyreCheck: string,
    public adBlue: string,
    public generalRepMain: string,
    public recall: string,
    public selectedService: string,
    public dealerCourtesyAvailable: string,
    public courtesyCar: string,
    public whileUwait: string,
    public firstName: string,
    public surname: string,
    public email: string,
    public telephone: string,
    public comments: string,
    public Address_1: string,
    public Address_2: string,
    public Town: string,
    public County: string,
    public paymentType: string,
    public totalPrice: string,
    public interval: string,
    public intervalMonth: string,
    public collectNdeliver: string,
    public servcosts = '',
    public isPOA: boolean,
    public isTextBased: boolean,
    public hasServicePlan: boolean,
    public healthCheck: string,
    public advisorAppointmentSts: string,
    public servSeasonalAirConSrvPrice: string,
    public servSeasonalCombinedPrice: string,
    public servSeasonalDetoxPackPrice: string,
    public servSeasonalBronzePackPrice: string,
    public servSeasonalGoldPrice: string,
    public servSeasonalSilverSrvPrice: string,
    public keyDropOff?: string,
    public isSelectedDateCorrect: boolean = false,
    public Q658: string = 'No',
  ) { }

  set location(location: string) {
    if (!location) {
      this.courtesyCar = '';
    }
    this._location = location;
  }

  get location(): string {
    return this._location;
  }
}

export class First {
  vehicleReg: string;
  mileage: string;
  postcode: string;
  isMOT: boolean;
}

export class AddressList {
  // addressList: Array<any>;
  constructor(
    public addressLines: any
  ) { }
}

export interface MyGlobal {
  formref: string;
}

export class EnquiryCreate {
  constructor(
    public customer: string,
    public pagerefc: string,
    public trackerref: string,
    public uniquerefc: string,
    public customer_hash: string,
    public formref: string,
    public session_c: string,
    public Q57: string,
    public Q2: string,
    public Q3: string,
    public Q9: string,
    public Q7: string,
    public Q8: string,
    public Q119: string,
    public Q120: string,
    public Q45: string,
    public Q41: string,
    public Q626: string,
    public Q282: string,
    public Q188: string,
    public Q658: string,
    public Q283: string,
    public Q99: string,
    public Q91: string,
    public Q186: string,
    public Q285: string,
    public Q284: string,
    public Q623: string,
    public Q68: string,
    public Q70: string,
    public Q71: string,
    public Q72: string,
    public Q101: string,
    public Q183: string,
    public encregno: string,
    public annualInspect: string,
    public TotalServiceCost: string
  ) { }
}
