/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./service-booking-form/service-booking-form.component.ngfactory";
import * as i2 from "./service-booking-form/service-booking-form.component";
import * as i3 from "./services/brands-config.service";
import * as i4 from "@angular/cdk/stepper";
import * as i5 from "./app.component";
import * as i6 from "./services/form-data.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-service-booking-form", [], null, null, null, i1.View_ServiceBookingFormComponent_0, i1.RenderType_ServiceBookingFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.ServiceBookingFormComponent, [i3.BrandsConfigService], null, null), i0.ɵprd(256, null, i4.MAT_STEPPER_GLOBAL_OPTIONS, i2.ɵ0, [])], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i5.AppComponent, [i6.FormDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, { formData: "formData", enquiryCreate: "enquiryCreate", addressList: "addressList" }, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
