// Service Details Component
import { OnInit, AfterViewInit } from '@angular/core';
import { FormDataService } from 'src/app/services/form-data.service';
import { GetServiceDetailsService } from 'src/app/services/get-service-details.service';
import * as moment from 'moment';
import { BrandsConfigService } from 'src/app/services/brands-config.service';
var ServiceDetailsComponent = /** @class */ (function () {
    function ServiceDetailsComponent(formDataService, getServiceDetailsService, brandsConfigService) {
        var _this = this;
        this.formDataService = formDataService;
        this.getServiceDetailsService = getServiceDetailsService;
        this.brandsConfigService = brandsConfigService;
        this.changeLocation = false;
        this.extraSelected = [];
        this.isToggle = false;
        // Checks for DataLayer
        this.ServiceMOT = false;
        this.COURTESYCAR = false;
        this.collectNdeliver = false;
        this.adBlue = false;
        this.servOption = "";
        this.dateRequired = false; // This value is needed to work-around the non-enforcement of validation on the date control etc....
        this.dateValid = false;
        // If user clicked the confirmation tick for courtesy car
        this.courtesyConfirmed = false;
        this.dealerSelected = false;
        this.availableDates = function (d) {
            // Check if it is a LEAD-6 dealer
            var isLead6DealerSource = _this.formData.whileUwait === 'WHILEUWAIT' && _this.serviceDetails.dealerDateSource === 'LEAD-6';
            if (isLead6DealerSource) {
                // For Saturday/Sunday return false
                if (d.getDay() > 5) {
                    return false;
                }
            }
            // Else return the date based on the available dates
            return _this.dealerDates[_this.convertDate(d)];
            // return this.formData.dealerAvailability[this.convertDate(d)];
        };
    }
    Object.defineProperty(ServiceDetailsComponent.prototype, "serviceDetails", {
        get: function () {
            return this._serviceDetails;
        },
        // Setter and getter for the service details node
        set: function (details) {
            if (details) {
                this._serviceDetails = details;
                // Display the alert if the dealer doesnt accept waiting appointment but the user has selected it
                if (details.dealerServiceControls
                    && details.dealerServiceControls.locnAllowsWait === 'no'
                    && this.formData.whileUwait === 'WHILEUWAIT') {
                    this.displayAlert = true;
                }
                else {
                    this.displayAlert = false;
                }
                if (details.locations[0] && this.formData.make === 'Vauxhall') {
                    if (details.locations[0].includes('Vauxhall')) {
                        this.isVauxhall = true;
                    }
                }
                else if (this.formData.make === 'Vauxhall' && this.formData.location.includes('Vauxhall')) {
                    this.isVauxhall = true;
                }
                else {
                    this.isVauxhall = false;
                }
                var isLead6DealerSource = this.formData.whileUwait === 'WHILEUWAIT' && details.dealerDateSource === 'LEAD-6';
                // If time slots are available
                if (details.dateAvailability
                    && details.dateAvailability.serviceAvailabilityData
                    && details.dateAvailability.serviceAvailabilityData[0].dlrSrvOpt) {
                    this.availableTimes = details.dateAvailability.serviceAvailabilityData[0].dlrSrvOpt;
                }
                else if (isLead6DealerSource) {
                    // Else if waiting appointment is selected and the dealer source is LEAD-6
                    // https://app.asana.com/0/751270265214859/1160572498867707/f
                    this.availableTimes = [
                        {
                            bkTime: '8:30'
                        },
                    ];
                    // For toyota the afternoon time is at 14:30
                    if (details.dealerServiceControls && /Toyota/i.test(details.dealerServiceControls.locnDsc)) {
                        this.availableTimes.push({
                            bkTime: '14:30'
                        });
                    }
                    else {
                        this.availableTimes.push({
                            bkTime: '13:00'
                        });
                    }
                }
                // If dates slots are available
                if (details.dealerAvailability && Object.keys(details.dealerAvailability).length) {
                    this.dealerDates = details.dealerAvailability;
                }
                else if (isLead6DealerSource) {
                    // Else if is LEAD-6 source remove Saturdays/Sundays from previous dates
                    this.formData.calendarStartDate = this._getCalendarStartDate({ dealerAvailability: this.dealerDates });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceDetailsComponent.prototype, "formData", {
        get: function () {
            return this._formData;
        },
        set: function (data) {
            this._formData = data;
        },
        enumerable: true,
        configurable: true
    });
    ServiceDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.formData);
        // this.first = this.formDataService.getFirst();
        // this.vehicle = this.formDataService.getVehicle();
        // this.serviceDetails = this.formDataService.getServiceDetails();
        // this.location = this.formDataService.getLocation();
        // console.log('loaded service details component');
        this.enquiryCreate = this.formDataService.getEnquiryData();
        this.formData = this.formDataService.getFormData();
        this.vehicle = this.formDataService.getVehicle();
        this.location = this.formDataService.getLocation();
        this.serviceDetails = this.formDataService.getServiceDetails();
        this.selectedTime = '';
        console.log(this.formData);
        // Subscribe to the service details response to update the view
        this.formDataService.serviceDetailsResponse$.subscribe(function (details) {
            _this.serviceDetails = details;
            if ((!_this.formData.postcodeFound && Object.keys(_this.formData.dealerServiceControls).length === 0) || _this.isToggle) {
                _this.changeLocation = true;
            }
            else {
                _this.changeLocation = false;
            }
            if (_this.formData.postcodeFound && !_this.dealerSelected) {
                _this.dealerSelected = true;
            }
        });
    };
    ServiceDetailsComponent.prototype.ngAfterViewInit = function () {
        console.log(this.formData.hasServicePlan);
        // this.formDataService.setDataLayer(window.location.href + 'service-details', 'Service details page of service booking form');
    };
    ServiceDetailsComponent.prototype.updateDataLayer = function () {
        var _this = this;
        this.formDataService.setDataLayer(window.location.href + 'personal-details', 'Personal details page of service booking form');
        window.dataLayer.push({ event: 'serviceBookingPersonalDetails' });
        this.formDataService.setDataLayerProduct(this.productType, this.productPrice);
        // Setting DataLayer for Analytics
        if (this.formData.servType === 'ServiceMOT' && !this.ServiceMOT) {
            this.extraSelected.push({ 'productType': 'serviceMot', 'productPrice': this.formData.servOptionCosts.servMOTPrice });
            this.ServiceMOT = true;
        }
        if (this.formData.courtesyCar === 'COURTESYCAR' && !this.COURTESYCAR) {
            this.extraSelected.push({ 'productType': 'COURTESYCAR', 'productPrice': this.formData.servOptionCosts.servCourtesyCarCost });
            this.COURTESYCAR = true;
        }
        if (this.formData.collectNdeliver.length > 1 && !this.collectNdeliver) {
            // tslint:disable-next-line: max-line-length
            this.extraSelected.push({ 'productType': 'collectNdeliver', 'productPrice': this.formData.servOptionCosts.servCollectnDeliverCost });
            this.collectNdeliver = true;
        }
        if (this.formData.adBlue.length > 1 && !this.adBlue) {
            this.extraSelected.push({ 'productType': 'adBlue', 'productPrice': '0.00' });
            this.adBlue = true;
        }
        if (this.extraSelected.length >= 1) {
            this.extraSelected.map(function (product) {
                _this.formDataService.setDataLayerProduct(product.productType, product.productPrice);
            });
        }
        this.formDataService.setDataLayerDetails();
    };
    ServiceDetailsComponent.prototype.setDatesValidator = function () {
        if (this.formData.whileUwait === 'WHILEUWAIT') {
            this.dateRequired = true;
            this.dateValid = false;
        }
        else {
            this.dateRequired = false;
        }
    };
    ServiceDetailsComponent.prototype.changeLocationToggle = function () {
        console.log(this.formData.locations.length === 0, 'loca');
        this.isToggle = true;
        this.changeLocation = true;
        if (this.formData.locations) {
            // console.log('Locations exist ' + JSON.stringify(this.formData.locations));
        }
        else if (this.formData.locations.length === 0) {
            this.formData.location = '';
            // this.formData.postcode = '';
            this.reQuote();
        }
    };
    ServiceDetailsComponent.prototype.reQuote = function () {
        var _this = this;
        if (this.formData.annualInspect !== '') {
            this.formData.interval = '';
        }
        this.selectedDate = undefined;
        this.formDataService.setSelectedDate('');
        // this.setDateSelected(); // makes two calls
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.formData.selectedDate, this.formData).subscribe(function (data) {
            _this.serviceDetails = data;
            if (_this.formData.isTextBased) {
                if (_this.formData.isPOA) {
                    _this.formData.intervalMonth = data.servcosts[0];
                }
                else {
                    _this.formData.intervalMonth = data.servcosts[1];
                }
            }
            _this.totalPrice = data.servOptionCosts.servTotalServiceCost;
            _this.formDataService.setPrice(_this.totalPrice);
            if (Object.keys(data.dealerAvailability).length) {
                _this.formData.calendarStartDate = _this._getCalendarStartDate(data);
            }
            else {
                _this.formData.calendarStartDate = null;
            }
        }, function (err) { return console.log(err); }, function () {
            _this.formDataService.setServiceDetails(_this.serviceDetails);
            _this.isToggle = false;
            _this.dealerSelected = true;
        });
        this.vehicle = this.formDataService.getVehicle();
    };
    ServiceDetailsComponent.prototype.reQuoteService = function () {
        var _this = this;
        if (this.formData.annualInspect !== '') {
            this.formData.interval = '';
        }
        this.selectedDate = undefined;
        this.formDataService.setSelectedDate('');
        // this.setDateSelected(); // makes two calls
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.formData.selectedDate, this.formData).subscribe(function (data) {
            _this.serviceDetails = data;
            if (_this.formData.isTextBased) {
                if (_this.formData.isPOA) {
                    _this.formData.intervalMonth = data.servcosts[0];
                }
                else {
                    _this.formData.intervalMonth = data.servcosts[1];
                }
            }
            _this.totalPrice = data.servOptionCosts.servTotalServiceCost;
            _this.formDataService.setPrice(_this.totalPrice);
            if (Object.keys(data.dealerAvailability).length) {
                _this.formData.calendarStartDate = _this._getCalendarStartDate(data);
            }
            else {
                _this.formData.calendarStartDate = null;
            }
        }, function (err) { return console.log(err); }, function () {
            _this.formDataService.setServiceDetails(_this.serviceDetails);
            _this.isToggle = false;
            _this.dealerSelected = true;
            _this.changeLocation = false;
        });
        this.vehicle = this.formDataService.getVehicle();
    };
    ServiceDetailsComponent.prototype.reQuoteLocation = function () {
        var _this = this;
        // this.serviceDetails.location = this.selectedLocation;
        // this.formDataService.setSelectedLocation(this.selectedLocation);
        // this.formDataService.setLocation(this.location);
        this.selectedDate = undefined;
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, '', this.formData).subscribe(function (data) {
            _this.serviceDetails = data;
            _this.formData.calendarStartDate = _this._getCalendarStartDate(data);
            if (Object.keys(data.dealerAvailability).length === 0) {
                _this.formData.calendarStartDate = null;
            }
            // this.setDateSelected();
        }, function (err) { return console.log(err); }, function () {
            _this.formDataService.setServiceDetails(_this.serviceDetails);
            _this.changeLocation = false;
            _this.dealerSelected = true;
        });
    };
    // This function converts the date picker dates to dd/mm/yyyy
    // so that our JSON can return true/false against them
    ServiceDetailsComponent.prototype.convertDate = function (inputFormat) {
        return moment(inputFormat, 'DD/MM/YYYY').format('DD/MM/YYYY');
        // function pad(s) {
        //     return (s < 10) ? '0' + s : s;
        // }
        // const d = new Date(inputFormat);
        // return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
    };
    ServiceDetailsComponent.prototype.setIntervalDefault = function (month, miles, cost, times, selectedService) {
        this.productType = miles;
        this.productPrice = cost;
        this.selectedInterval = month + ' Months ' + miles + ' Miles ' + cost + ' Duration ' + times;
        this.intervalMonth = month;
        this.formDataService.setInterval(this.selectedInterval, this.intervalMonth);
        this.formDataService.setSelectedService(selectedService);
    };
    ServiceDetailsComponent.prototype.setIntervalSelected = function (month, miles, cost, times, selectedService) {
        var _this = this;
        this.productType = miles;
        this.productPrice = cost;
        this.servOption = month;
        this.selectedInterval = month + ' Months ' + miles + ' Miles ' + cost + ' Duration ' + times;
        this.intervalMonth = month;
        this.formDataService.setInterval(this.selectedInterval, this.intervalMonth);
        this.formDataService.setSelectedService(selectedService);
        if (this.selectedDate) {
            this.formData.calendarStartDate = this.selectedDate;
            this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.convertDate(this.selectedDate), this.formData)
                .subscribe(function (data) {
                _this.serviceDetails = data;
                _this.formData.servOptionCosts = data.servOptionCosts;
                _this.totalPrice = _this.formData.servOptionCosts.servTotalServiceCost;
                _this.formDataService.setPrice(_this.totalPrice);
            }, function (err) { return console.log(err); }, function () { return _this.formDataService.setServiceDetails(_this.serviceDetails); });
        }
        else {
            this.getServiceDetailsService.getServiceDetailsWithoutDate(this.vehicle, this.location, this.formData).subscribe(function (data) {
                _this.serviceDetails = data;
                _this.formData.servOptionCosts = data.servOptionCosts;
                _this.totalPrice = _this.formData.servOptionCosts.servTotalServiceCost;
                _this.formDataService.setPrice(_this.totalPrice);
            }, function (err) { return console.log(err); }, function () { _this.isToggle = false; _this.formDataService.setServiceDetails(_this.serviceDetails); });
        }
    };
    ServiceDetailsComponent.prototype.setDateSelected = function () {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        // console.log(this.convertDate(this.selectedDate) + 'Date has been selected, need to run the service ' + JSON.stringify(this.first));
        this.formDataService.setSelectedDate(this.convertDate(this.selectedDate));
        // Getting time slots
        // tslint:disable-next-line:max-line-length
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.convertDate(this.selectedDate), this.formData).subscribe(function (data) {
            _this.selectedTime = '';
            _this.serviceDetails = data;
            _this.formData.servOptionCosts = data.servOptionCosts;
            _this.totalPrice = _this.formData.servOptionCosts.servTotalServiceCost;
            if (_this.totalPrice === '')
                _this.totalPrice = '0';
            _this.formDataService.setPrice(_this.totalPrice);
        }, function (err) { return console.log(err); }, function () { _this.formDataService.setServiceDetails(_this.serviceDetails); _this.changeLocation = false; });
    };
    ServiceDetailsComponent.prototype.setTimeSelected = function () {
        // console.log('Preferred time is: ' + this.selectedTime);
        this.formDataService.setSelectedTime(this.selectedTime);
    };
    ServiceDetailsComponent.prototype.scrollToTop = function () {
        this.formDataService.scrollToTop();
    };
    ServiceDetailsComponent.prototype._getCalendarStartDate = function (data) {
        if (data.dealerAvailability[0] !== undefined) {
            return new Date(parseInt(Object.keys(data.dealerAvailability)[0].substr(6, 4), 10), parseInt(Object.keys(data.dealerAvailability)[0].substr(3, 2), 10) - 1);
        }
    };
    ServiceDetailsComponent.prototype.checkSeasonalOption = function () {
        if (this.formData.seasonalCheck === 'Yes') {
            return !this.seasonalRadioBtn;
        }
        else {
            return null;
        }
    };
    ServiceDetailsComponent.prototype.checkCourtesyConfirmation = function () {
        if (this.formData.courtesyCar === 'COURTESYCAR') {
            if (this.courtesyConfirmed) {
                return;
            }
            else {
                return false;
            }
        }
        else {
            return;
        }
    };
    return ServiceDetailsComponent;
}());
export { ServiceDetailsComponent };
