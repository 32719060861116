import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import 'hammerjs';


import { MatFormFieldModule, MatInputModule, MatDatepickerModule, MatDateFormats, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';


import {DemoMaterialModule} from './material-module';


import { AppComponent } from './app.component';
import { ServiceBookingFormComponent } from './service-booking-form/service-booking-form.component';
import { PostcodeLookupService } from './services/postcode-lookup.service';
import { RouterModule } from '@angular/router';
import { appRoutes } from './service-booking-form/routes';
import { VehicleDetailsComponent } from './service-booking-form/vehicle-details/vehicle-details.component';
import { ServiceDetailsComponent } from './service-booking-form/service-details/service-details.component';
import { PersonalDetailsComponent } from './service-booking-form/personal-details/personal-details.component';
import { FormDataService } from './services/form-data.service';
import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { APP_BASE_HREF } from '@angular/common';
import { ImgLoaderDirective } from './directives/img-loader.directive';
import { TotalCostsComponent } from './components/total-costs/total-costs.component';
import { BrandsConfigService } from './services/brands-config.service';

const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ServiceBookingFormComponent,
    VehicleDetailsComponent,
    ServiceDetailsComponent,
    PersonalDetailsComponent,
    ImgLoaderDirective,
    TotalCostsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    MatFormFieldModule, MatInputModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    DemoMaterialModule,
    RouterModule.forRoot(appRoutes)
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
      PostcodeLookupService,
      FormDataService,
      BrandsConfigService,
      {
        provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
        useValue: {strict: true}
      },
      // {
      //     provide: MAT_DATE_LOCALE,
      //     useValue: 'en-GB'
      // },
      {
        provide: MAT_DATE_FORMATS,
        useValue: MY_FORMATS,
      },
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [
          MAT_DATE_LOCALE
        ]
      },
      {
          provide: MAT_STEPPER_GLOBAL_OPTIONS,
          useValue: {
              showError: true
          }
      },
      {
          provide: APP_BASE_HREF,
          useValue: window['base-href']
      }
    ],
  bootstrap: [AppComponent]
})


export class AppModule {}
