// Service Details Component
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';
import { First, FormData, EnquiryCreate } from '../../models/formData';
import { Location } from '../../models/location';
import { Vehicle } from '../../models/vehicle';
import { ServiceDetails } from 'src/app/models/serviceDetails';
import { GetServiceDetailsService } from 'src/app/services/get-service-details.service';
import * as moment from 'moment';
import { BrandsConfigService } from 'src/app/services/brands-config.service';

@Component({
    selector: 'app-service-details',
    templateUrl: './service-details.component.html',
    viewProviders: [{
        provide: ControlContainer,
        useExisting: NgForm
    }]
})

export class ServiceDetailsComponent implements OnInit, AfterViewInit {

    first: First;
    vehicle: Vehicle;
    private _serviceDetails: ServiceDetails;
    changeLocation = false;
    selectedLocation: any;
    location: Location;
    selectedDate: any;
    selectedTime: any;
    timeSlots: any[];
    selectedInterval: string;
    totalPrice: string;
    _formData: FormData;
    enquiryCreate: EnquiryCreate;
    intervalMonth: string;
    farnell: boolean;
    productType: string;
    productPrice: string;
    extraSelected = [];
    isToggle: boolean = false;
    courtesyChecked: boolean;
    keyDropOffChecked: boolean;
    collectionAndDeliveryChecked: boolean;
    isVauxhall: boolean;

    // Checks for DataLayer
    ServiceMOT: boolean = false;
    COURTESYCAR: boolean = false;
    collectNdeliver: boolean = false;
    adBlue: boolean = false;
    servOption: string = "";
    dateRequired = false; // This value is needed to work-around the non-enforcement of validation on the date control etc....
    dateValid = false;

    seasonalRadioBtn: boolean;

    // If user clicked the confirmation tick for courtesy car
    courtesyConfirmed = false;

    // Store the available dates/times to fix LEAD-6 dealer source
    dealerDates: any[];
    availableTimes: any;

    // If the alert info about extra options should be displayed
    displayAlert: boolean;

    dealerSelected: boolean = false;

    // Setter and getter for the service details node
    set serviceDetails(details: ServiceDetails) {
        if (details) {
            this._serviceDetails = details;

            // Display the alert if the dealer doesnt accept waiting appointment but the user has selected it
            if (
                details.dealerServiceControls
                && details.dealerServiceControls.locnAllowsWait === 'no'
                && this.formData.whileUwait === 'WHILEUWAIT'
            ) {
                this.displayAlert = true;
            } else {
                this.displayAlert = false;
            }

            if (details.locations[0] && this.formData.make === 'Vauxhall') {
                if (details.locations[0].includes('Vauxhall')) {
                    this.isVauxhall = true;
                }
            } else if (this.formData.make === 'Vauxhall' && this.formData.location.includes('Vauxhall')) {
                this.isVauxhall = true;
            } else {
                this.isVauxhall = false;
            }

            const isLead6DealerSource = this.formData.whileUwait === 'WHILEUWAIT' && details.dealerDateSource === 'LEAD-6';

            // If time slots are available
            if (
                details.dateAvailability
                && details.dateAvailability.serviceAvailabilityData
                && details.dateAvailability.serviceAvailabilityData[0].dlrSrvOpt
            ) {
                this.availableTimes = details.dateAvailability.serviceAvailabilityData[0].dlrSrvOpt;
            } else if (isLead6DealerSource) {
                // Else if waiting appointment is selected and the dealer source is LEAD-6
                // https://app.asana.com/0/751270265214859/1160572498867707/f
                this.availableTimes = [
                    {
                        bkTime: '8:30'
                    },
                ];

                // For toyota the afternoon time is at 14:30
                if (details.dealerServiceControls && /Toyota/i.test(details.dealerServiceControls.locnDsc)) {
                    this.availableTimes.push({
                        bkTime: '14:30'
                    });
                } else {
                    this.availableTimes.push({
                        bkTime: '13:00'
                    });
                }
            }

            // If dates slots are available
            if (details.dealerAvailability && Object.keys(details.dealerAvailability).length) {
                this.dealerDates = details.dealerAvailability;
            } else if (isLead6DealerSource) {
                // Else if is LEAD-6 source remove Saturdays/Sundays from previous dates
                this.formData.calendarStartDate = this._getCalendarStartDate({ dealerAvailability: this.dealerDates });
            }
        }
    }

    get serviceDetails(): ServiceDetails {
        return this._serviceDetails;
    }

    set formData(data: FormData) {
        this._formData = data;
    }

    get formData(): FormData {
        return this._formData;
    }

    constructor(
        public formDataService: FormDataService,
        public getServiceDetailsService: GetServiceDetailsService,
        public brandsConfigService: BrandsConfigService
    ) { }


    ngOnInit() {
      console.log(this.formData)
        // this.first = this.formDataService.getFirst();
        // this.vehicle = this.formDataService.getVehicle();
        // this.serviceDetails = this.formDataService.getServiceDetails();
        // this.location = this.formDataService.getLocation();
        // console.log('loaded service details component');
        this.enquiryCreate = this.formDataService.getEnquiryData();
        this.formData = this.formDataService.getFormData();
        this.vehicle = this.formDataService.getVehicle();
        this.location = this.formDataService.getLocation();
        this.serviceDetails = this.formDataService.getServiceDetails();
        this.selectedTime = '';
        console.log(this.formData);

        // Subscribe to the service details response to update the view
        this.formDataService.serviceDetailsResponse$.subscribe(details => {
            this.serviceDetails = details;
            if ((!this.formData.postcodeFound && Object.keys(this.formData.dealerServiceControls).length === 0) || this.isToggle) {
                this.changeLocation = true;
            } else {
                this.changeLocation = false;
            }

            if (this.formData.postcodeFound && !this.dealerSelected) {
                this.dealerSelected = true;
            }
        });
    }

    ngAfterViewInit() {
        console.log(this.formData.hasServicePlan)
        // this.formDataService.setDataLayer(window.location.href + 'service-details', 'Service details page of service booking form');
    }

    updateDataLayer() {
        this.formDataService.setDataLayer(window.location.href + 'personal-details', 'Personal details page of service booking form');
        (window as any).dataLayer.push({ event: 'serviceBookingPersonalDetails' });
        this.formDataService.setDataLayerProduct(this.productType, this.productPrice);
        // Setting DataLayer for Analytics
        if (this.formData.servType === 'ServiceMOT' && !this.ServiceMOT) {
            this.extraSelected.push({ 'productType': 'serviceMot', 'productPrice': this.formData.servOptionCosts.servMOTPrice });
            this.ServiceMOT = true;
        }
        if (this.formData.courtesyCar === 'COURTESYCAR' && !this.COURTESYCAR) {
            this.extraSelected.push({ 'productType': 'COURTESYCAR', 'productPrice': this.formData.servOptionCosts.servCourtesyCarCost });
            this.COURTESYCAR = true;
        }
        if (this.formData.collectNdeliver.length > 1 && !this.collectNdeliver) {
            // tslint:disable-next-line: max-line-length
            this.extraSelected.push({ 'productType': 'collectNdeliver', 'productPrice': this.formData.servOptionCosts.servCollectnDeliverCost });
            this.collectNdeliver = true;
        }
        if (this.formData.adBlue.length > 1 && !this.adBlue) {
            this.extraSelected.push({ 'productType': 'adBlue', 'productPrice': '0.00' })
            this.adBlue = true;
        }
        if (this.extraSelected.length >= 1) {
            this.extraSelected.map(product => {
                this.formDataService.setDataLayerProduct(product.productType, product.productPrice);
            });
        }
        this.formDataService.setDataLayerDetails();
    }

    setDatesValidator() {
        if (this.formData.whileUwait === 'WHILEUWAIT') {
            this.dateRequired = true;
            this.dateValid = false;
        } else {
            this.dateRequired = false;
        }
    }


    changeLocationToggle() {
      console.log(this.formData.locations.length === 0, 'loca');
        this.isToggle = true;
        this.changeLocation = true;
        if (this.formData.locations) {
            // console.log('Locations exist ' + JSON.stringify(this.formData.locations));
        } else if (this.formData.locations.length === 0) {
            this.formData.location = '';
            // this.formData.postcode = '';
            this.reQuote();
        }
    }

    reQuote() {
        if (this.formData.annualInspect !== '') {
            this.formData.interval = '';
        }
        this.selectedDate = undefined;
        this.formDataService.setSelectedDate('');
        // this.setDateSelected(); // makes two calls
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.formData.selectedDate, this.formData).subscribe(
            (data: any) => {
                this.serviceDetails = data;
                if (this.formData.isTextBased) {
                    if (this.formData.isPOA) {
                        this.formData.intervalMonth = data.servcosts[0];
                    } else {
                        this.formData.intervalMonth = data.servcosts[1];
                    }
                }
                this.totalPrice = data.servOptionCosts.servTotalServiceCost;
                this.formDataService.setPrice(this.totalPrice);

                if (Object.keys(data.dealerAvailability).length) {
                    this.formData.calendarStartDate = this._getCalendarStartDate(data);
                } else {
                    this.formData.calendarStartDate = null;
                }
            },
            (err: any) => console.log(err),
            () => {
                this.formDataService.setServiceDetails(this.serviceDetails);
                this.isToggle = false;
                this.dealerSelected = true;

            }
        );
        this.vehicle = this.formDataService.getVehicle();
    }

    reQuoteService() {
        if (this.formData.annualInspect !== '') {
            this.formData.interval = '';
        }
        this.selectedDate = undefined;
        this.formDataService.setSelectedDate('');
        // this.setDateSelected(); // makes two calls
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.formData.selectedDate, this.formData).subscribe(
            (data: any) => {
                this.serviceDetails = data;
                if (this.formData.isTextBased) {
                    if (this.formData.isPOA) {
                        this.formData.intervalMonth = data.servcosts[0];
                    } else {
                        this.formData.intervalMonth = data.servcosts[1];
                    }
                }
                this.totalPrice = data.servOptionCosts.servTotalServiceCost;
                this.formDataService.setPrice(this.totalPrice);

                if (Object.keys(data.dealerAvailability).length) {
                    this.formData.calendarStartDate = this._getCalendarStartDate(data);
                } else {
                    this.formData.calendarStartDate = null;
                }

            },
            (err: any) => console.log(err),
            () => {
                this.formDataService.setServiceDetails(this.serviceDetails);
                this.isToggle = false;
                this.dealerSelected = true;
                this.changeLocation = false;

            }
        );
        this.vehicle = this.formDataService.getVehicle();
    }


    reQuoteLocation() {
        // this.serviceDetails.location = this.selectedLocation;
        // this.formDataService.setSelectedLocation(this.selectedLocation);
        // this.formDataService.setLocation(this.location);
        this.selectedDate = undefined;
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, '', this.formData).subscribe(
            (data: any) => {
                this.serviceDetails = data;
                this.formData.calendarStartDate = this._getCalendarStartDate(data);
                if (Object.keys(data.dealerAvailability).length === 0) {
                    this.formData.calendarStartDate = null;
                }
                // this.setDateSelected();
            },
            (err: any) => console.log(err),
            () => {
                this.formDataService.setServiceDetails(this.serviceDetails);
                this.changeLocation = false;
                this.dealerSelected = true;
            }
        );
    }

    // This function converts the date picker dates to dd/mm/yyyy
    // so that our JSON can return true/false against them
    convertDate(inputFormat) {
        return moment(inputFormat, 'DD/MM/YYYY').format('DD/MM/YYYY');
        // function pad(s) {
        //     return (s < 10) ? '0' + s : s;
        // }
        // const d = new Date(inputFormat);
        // return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
    }

    availableDates = (d: Date): boolean => {
        // Check if it is a LEAD-6 dealer
        const isLead6DealerSource = this.formData.whileUwait === 'WHILEUWAIT' && this.serviceDetails.dealerDateSource === 'LEAD-6';
        if (isLead6DealerSource) {
            // For Saturday/Sunday return false
            if (d.getDay() > 5) {
                return false;
            }
        }

        // Else return the date based on the available dates
        return this.dealerDates[this.convertDate(d)];
        // return this.formData.dealerAvailability[this.convertDate(d)];
    }

    setIntervalDefault(month: string, miles, cost, times, selectedService) {
        this.productType = miles;
        this.productPrice = cost;
        this.selectedInterval = month + ' Months ' + miles + ' Miles ' + cost + ' Duration ' + times;
        this.intervalMonth = month;
        this.formDataService.setInterval(this.selectedInterval, this.intervalMonth);
        this.formDataService.setSelectedService(selectedService);
    }

    setIntervalSelected(month: string, miles, cost, times, selectedService) {
        this.productType = miles;
        this.productPrice = cost;
        this.servOption = month;
        this.selectedInterval = month + ' Months ' + miles + ' Miles ' + cost + ' Duration ' + times;
        this.intervalMonth = month;
        this.formDataService.setInterval(this.selectedInterval, this.intervalMonth);
        this.formDataService.setSelectedService(selectedService);
        if (this.selectedDate) {
            this.formData.calendarStartDate = this.selectedDate;

            this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.convertDate(this.selectedDate), this.formData)
                .subscribe((data: any) => {
                    this.serviceDetails = data;
                    this.formData.servOptionCosts = data.servOptionCosts;
                    this.totalPrice = this.formData.servOptionCosts.servTotalServiceCost;
                    this.formDataService.setPrice(this.totalPrice);
                },
                    (err: any) => console.log(err),
                    () => this.formDataService.setServiceDetails(this.serviceDetails)
                );
        } else {
            this.getServiceDetailsService.getServiceDetailsWithoutDate(this.vehicle, this.location, this.formData).subscribe(
                (data: any) => {
                    this.serviceDetails = data;
                    this.formData.servOptionCosts = data.servOptionCosts;
                    this.totalPrice = this.formData.servOptionCosts.servTotalServiceCost;
                    this.formDataService.setPrice(this.totalPrice);
                },
                (err: any) => console.log(err),
                () => {this.isToggle = false;this.formDataService.setServiceDetails(this.serviceDetails)}
            );
        }
    }

    setDateSelected() {
        // tslint:disable-next-line:max-line-length
        // console.log(this.convertDate(this.selectedDate) + 'Date has been selected, need to run the service ' + JSON.stringify(this.first));
        this.formDataService.setSelectedDate(this.convertDate(this.selectedDate));

        // Getting time slots
        // tslint:disable-next-line:max-line-length
        this.getServiceDetailsService.getServiceDetails(this.vehicle, this.location, this.convertDate(this.selectedDate), this.formData).subscribe(
            (data: any) => {
                this.selectedTime = '';
                this.serviceDetails = data;
                this.formData.servOptionCosts = data.servOptionCosts;
                this.totalPrice = this.formData.servOptionCosts.servTotalServiceCost;
                if (this.totalPrice === '') this.totalPrice = '0';
                this.formDataService.setPrice(this.totalPrice);
            },
            (err: any) => console.log(err),

            () =>{this.formDataService.setServiceDetails(this.serviceDetails); this.changeLocation = false;}
        );
    }

    setTimeSelected() {
        // console.log('Preferred time is: ' + this.selectedTime);
        this.formDataService.setSelectedTime(this.selectedTime);
    }

    scrollToTop() {
        this.formDataService.scrollToTop();
    }

    private _getCalendarStartDate(data: any): Date {
        if (data.dealerAvailability[0] !== undefined) {
            return new Date(
                parseInt(Object.keys(data.dealerAvailability)[0].substr(6, 4), 10),
                parseInt(Object.keys(data.dealerAvailability)[0].substr(3, 2), 10) - 1
            );
        }
    }


  checkSeasonalOption() {
    if (this.formData.seasonalCheck === 'Yes') {

     return !this.seasonalRadioBtn;
    } else {

     return  null;
    }
    }

    checkCourtesyConfirmation() {
        if (this.formData.courtesyCar === 'COURTESYCAR') {
            if (this.courtesyConfirmed) {
                return;
            } else {
                return false;
            }
        } else {
            return;
        }
    }
}
