export class ServiceDetails {

    constructor(
        public servIntervals: Array<any>,
        public dealerServiceControls: any,
        public locations: Array<any>,
        public locnimage: string,
        public isMot: boolean,
        public location: string,
        public dealerAvailability: Array<any>,
        public dealerCourtesyAvailable: string,
        public selectedDate: string,
        public selectedTime: string,
        public whileUwait: string,
        public servType: string,
        public servOptionCosts: any,
        public seasonalCheck: string,
        public tyreCheck: string,
        public adBlue: string,
        public generalRepMain: string,
        public recall: string,
        public selectedService: string,
        public courtesyCar: string,
        public selectedInterval: string,
        public collectNdeliver: string,
        public seasonalDetox: string,
        public seasonalAirConSrv: string,
        public seasonalCombined: string,
        public seasonalBronze: string,
        public seasonalSilver: string,
        public seasonalGold: string,
        public dateAvailability?: { dateSelected: string; serviceAvailabilityData: any[]; },
        public dealerDateSource?: string,
        public healthCheck?: string,
        public advisorAppointmentSts?: string,
        public Q658: string = 'No',
        // public dateAvailability: string,
        // public serviceAvailabilityData: Array<any>,
        // public timeSlots: Array<any>
    ) { }
}

export class Appointment {
    constructor(
        public selectedDate: string,
        public selectedTime: string

    ) { }
}
// serviceAvailabilityData
