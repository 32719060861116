<p>
    Step 3 of 3 – Just a few details and we’re done. We look forward to seeing you soon
</p>

<fieldset class="form__fieldset" *ngIf="!submitResponse" ngModelGroup="personalGroup" #personalGroup="ngModelGroup">

    <div class="row change-order--mobile-down">

        <div class="xs-col-12 m-col-9 order--2">
            <ng-container *ngIf="formData.isSelectedDateCorrect">
                <h4 class="form__question-heading">Please complete all fields</h4>

                <div class="form__group row">
                    <div class="xs-col-12 s-col-4">
                        <input #name="ngModel" name="Q2" type="text" [(ngModel)]="formData.firstName"
                            [pattern]="regexPatterns.name" placeholder="First name" required />
                        <div class="form__message form__message--error"
                            *ngIf="name.invalid && (name.dirty || name.touched)">
                            {{ name.errors.required ? 'First name is required' : 'Please input a valid name' }}
                        </div>
                    </div>

                    <div class="xs-col-12 s-col-4 pull-s-4">
                        <input #surname="ngModel" name="Q3" type="text" [(ngModel)]="formData.surname" [pattern]="regexPatterns.name" placeholder="Last name" required />
                        <div class="form__message form__message--error"
                            *ngIf="surname.invalid && (surname.dirty || surname.touched)">
                            {{ surname.errors.required ? 'Last name is required' : 'Please input a valid name' }}
                        </div>
                    </div>
                    <div class="xs-col-12 s-col-6" *ngIf="formData.postcodeFound">
                      <select [(ngModel)]="formData.Address_1" name="Q7" required>
                          <option value disabled active>Select your address</option>
                          <option value="{{addressToId(address.Address_1)}}"
                          *ngFor="let address of addressList.addressLines.Addresslines">{{address.Address_1}}
                          </option>
                      </select>
                    </div>
                    <div class="xs-col-12 s-col-6" *ngIf="formData.postcodeFound">
                        <h4 class="heading--grouped text-size--medium line-height--smaller">Your address:</h4>
                        <address id="address" *ngIf="addressList.addressLines.Addresslines"
                            class="form__address form__address--no-margin text-size--medium line-height--smaller">
                            {{formData.Address_1}} {{ addressList.addressLines?.Addresslines[0].Address_2}}<br>
                            {{ addressList.addressLines?.Addresslines[0].Town}},
                            {{ addressList.addressLines?.Addresslines[0].County}} {{formData.postcode}}<br>
                        </address>
                    </div>

                    <div class="xs-col-12 s-col-5" *ngIf="!formData.postcodeFound">
                      <input name="Q90" type="text" [(ngModel)]="formData.Address_1" placeholder="Your address" required />
                    </div>

                    <div class="xs-col-12 s-col-3 s-pull-4" *ngIf="!formData.postcodeFound">
                      <input name="Q91" type="text" [(ngModel)]="formData.postcode" placeholder="Your postcode" required />
                    </div>

                    <div class="xs-col-12 s-col-5" *ngIf="!formData.postcodeFound">
                      <input name="Q92" type="text" [(ngModel)]="formData.Town" placeholder="Your town" required />
                    </div>

                    <div class="xs-col-12 s-col-3 pull-s-3" *ngIf="!formData.postcodeFound">
                      <select [(ngModel)]="formData.County" name="Q93" required>
                        <option value disabled active>Select your county</option>
                        <option value="{{county}}" *ngFor="let county of counties">
                          {{county}}
                        </option>
                      </select>
                    </div>

                    <div class="xs-col-12 s-col-4">
                        <input #email="ngModel" name="Q41" type="email" [(ngModel)]="formData.email"
                            [pattern]="regexPatterns.email" placeholder="Email address" required />
                        <div class="form__message form__message--error"
                            *ngIf="email.invalid && (email.dirty || email.touched)">
                            {{ email.errors.required ? 'Email is required' : 'Please input a valid email' }}
                        </div>
                    </div>

                    <div class="xs-col-12 s-col-4 pull-s-4">
                        <input #telephone="ngModel" name="Q45" type="text" [(ngModel)]="formData.telephone" [pattern]="regexPatterns.phoneUK" placeholder="Phone number" />
                        <div class="form__message form__message--error"
                            *ngIf="telephone.invalid && (telephone.dirty || telephone.touched)">
                            {{ telephone.errors.required ? 'Phone number is required' : 'Please input a valid phone number' }}
                        </div>
                    </div>
                </div>

                <div class="form__group radio-btn-container" *ngIf="!formData.hasServicePlan">
                    <h4 class="form__question-heading ">Payment type</h4>
                    <div class="radio-btn" id="step3-cash">
                        <label class="form__label font--bold">
                            <span *ngIf="formData.dealerServiceControls.locnCashSts === ''">Cash/</span>Debit/Credit
                            Card
                            <input type="radio" name="paymentType" #paymentType="ngModel" required
                                [(ngModel)]="formData.paymentType"
                                value="Cash/Debit/Credit Card"
                                [value]="formData.dealerServiceControls.locnCashSts === '' ? 'Cash/Debit/Credit Card' : 'Debit/Credit Card'" />
                            <span class="radio-btn__checkmark"></span>
                        </label>

                    </div>

                    <div class="radio-btn" id="step3-service-plan">
                        <label class="form__label font--bold">Service plan
                            <span class="tooltip">
                                <i class="fal fa-info-circle text--light-grey"></i>
                                <div class="tooltip__content">
                                    <p class="tooltip__text small">This service will be invoiced to your service plan
                                        account. As long as your service plan account contains sufficient funds, you
                                        will
                                        not have a bill to pay.</p>
                                </div>
                            </span>
                            <input type="radio" name="paymentType" [(ngModel)]="formData.paymentType" value="splan" />
                            <span class="radio-btn__checkmark"></span>
                        </label>
                    </div>

                    <div class="radio-btn" id="step3-motability">
                        <label class="form__label font--bold">Motability
                            <span class="tooltip">
                                <i class="fal fa-info-circle text--light-grey"></i>
                                <div class="tooltip__content">
                                    <p class="tooltip__text small">Please disregard the pricing quoted above as your
                                        servicing will be billed directly to Motability.</p>
                                </div>
                            </span>
                            <input type="radio" name="paymentType" [(ngModel)]="formData.paymentType"
                                value="motability" />
                            <span class="radio-btn__checkmark"></span>
                        </label>
                    </div>

                    <div class="radio-btn" id="step3-account">
                        <label class="form__label font--bold">Account
                            <span class="tooltip">
                                <i class="fal fa-info-circle text--light-grey"></i>
                                <div class="tooltip__content">
                                    <p class="tooltip__text small">If your vehicle servicing is covered by a maintenance
                                        contract with a Leasing Company, your service will be invoiced directly to the
                                        Leasing Company, so please disregard the quoted price.</p>
                                </div>
                            </span>
                            <input type="radio" name="paymentType" [(ngModel)]="formData.paymentType" value="account" />
                            <span class="radio-btn__checkmark"></span>
                        </label>
                    </div>
                    <div *ngIf="paymentType.invalid && paymentType.dirty">
                        Payment type is required.
                    </div>
                </div><!-- end form group -->

                <div class="form__group form__element--small clearfix">
                    <p class="opt-out small text--medium-grey" ng-show="formE83.Q584">
                        We will use this information to contact you about your chosen vehicle. We will also retain it in
                        case you contact us in the future. You can tell us at any time if you don't want us to contact
                        you.
                        For more details please see our
                        <a class="body-link body-link--underline" href="/privacy-policy/" target="_blank">Privacy
                            Policy</a>.
                    </p>
                    <div class="checkbox">
                        <label class="form__label">
                            Yes, I agree.
                            <input type="checkbox" name="OPT_IN" [(ngModel)]="isChecked"
                                (change)="termsAccepted = isChecked" class="clearFormatt opt-out__checkbox" value="no"
                                required>
                            <span class="checkbox__checkmark"></span>
                        </label>
                    </div>
                </div>

                <footer class="form__footer">
                    <div class="form__element-wrapper form__message--hover form__message--inline">
                        <button id="step3-continue" id="submitbtn" class="btn btn--primary form__btn"
                            (click)="submitData()"
                            [attr.disabled]="(personalGroup.invalid ? true : null) && !termsAccepted">Confirm and Book
                            Appointment</button>
                        <!-- <button matStepperPrevious type="button">Back</button> [disabled]="form.invalid" -->
                        <div class="form__message form__message--error" *ngIf="personalGroup.invalid && !termsAccepted">
                            Please
                            complete all
                            fields</div>
                    </div>
                </footer>
            </ng-container>

            <ng-container *ngIf="!formData.isSelectedDateCorrect">
                <section class="section--x-small text-align--center">
                    <div class="alert-box icon__info alert--error margin-0">
                        <p>
                            Oops! Something went wrong. Please try again or
                            contact us from our <a class="body-link--underline" href="/contact-us/">contact page</a>.
                        </p>
                    </div>
                </section>
            </ng-container>
        </div>

        <div class="xs-col-12 m-col-3 order--1">
            <aside class="form__summary tile__column tile--with-border-radius background--white">

                <div class="summary__img tile-img__container hide--tablet-down"><img [src]="formData?.locnimage"
                        [alt]="formData?.location">
                </div>
                <div class="tile-body form__service-details">
                    <h4 class="heading--grouped">
                        <span
                            *ngIf="formData.servType">{{formData.servType === 'ServiceMOT' ? 'Service & MOT' : formData.servType}}
                            <span
                                *ngIf="formData.intervalMonth && !formData.isTextBased && !formData.isMOT">({{formData.intervalMonth}}
                                months)</span>
                            <span
                                *ngIf="formData.intervalMonth && formData.isTextBased">({{formData.intervalMonth}})</span><br>
                            <span *ngIf="formData.annualInspect">({{formData.servAnnualInspectionLbl}})</span><br></span>
                        <span *ngIf="formData.seasonalCheck === 'Yes'">Seasonal Check <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalBronzeSelected | titlecase)  ===  'Yes'"> - Bronze</span>
                        <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalSilverSelected | titlecase)  ===  'Yes'"> - Silver</span>
                        <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalGoldSelected | titlecase)  ===  'Yes'"> - Gold</span></span>

                       <!--  <span *ngIf="formData.servOptionCosts?.servOptionsSelected?.servSeasonalAirConSelected === 'Yes'">AirCon<br></span>
                        <span *ngIf="formData.servOptionCosts?.servOptionsSelected?.servSeasonalCombinedSelected === 'Yes'">Combined<br></span> -->
                        <span *ngIf="formData.tyreCheck === 'Yes'">Tyre check<br></span>
                        <span *ngIf="formData.generalRepMain === 'Yes'">General Repair and Maintenance<br></span>
                        <span *ngIf="formData.recall === 'Yes'">Recall<br></span>
                        <span *ngIf="formData.healthCheck === 'Yes'">Health check<br></span>
                    </h4>
                    <p *ngIf="formData.courtesyCar === 'COURTESYCAR' || checkAdditionalServices()"
                        class="tile-text--grouped text--medium-grey text-size--medium">plus</p>
                    <h4 class="heading--grouped">
                        <span *ngIf="formData.courtesyCar === 'COURTESYCAR'">Courtesy {{ brandsConfigService.getVehicleName(false, formData.vehTyp) | titlecase }}</span>
                        <span
                            *ngIf="formData.courtesyCar === 'COURTESYCAR' && formData.collectNdeliver === 'COLLECTANDDELIVER'">,&nbsp;</span>
                        <span *ngIf="formData.collectNdeliver === 'COLLECTANDDELIVER'">Collection & delivery</span>
                    </h4>
                    <h4 class="heading--grouped">
                        <div *ngFor="let option of formData.servTechOptions">
                            <span *ngIf="option.servTechSelected === 'yes'">{{ option.servTechLbl }}</span>
                        </div>
                    </h4>
                    <p class="tile-text--grouped text--medium-grey text-size--medium">at</p>
                    <h4 class="heading--grouped">{{formData.location}}</h4>
                    <!-- <h4 class="heading--grouped">{{formData.location}}, {{TBC POSTCODE: B5 7AZ}}</h4> -->
                    <p class="tile-text--grouped text--medium-grey text-size--medium">on</p>
                    <h4>{{formData.selectedDate}} <span class="text-size--medium"
                            *ngIf="formData.selectedTime !== ''">at</span> {{formData.selectedTime}}
                    </h4>

                    <ng-container *ngTemplateOutlet="totalCostsTemp"></ng-container>
                      <!-- <a class="body-link text-size--medium font--bold" (click)="stepper.reset()">Change details</a> -->
                </div>
            </aside>
        </div>
    </div><!-- end row -->
</fieldset>



<div *ngIf="submitResponse">
    <div class="alert-box alert--error icon__info">
        <div innerHTML="{{submitResponse.response}}"></div>
    </div>

    <div class="row">
        <div class="xs-col-12 m-col-3">
            <aside class="form__summary tile__column tile--with-border-radius background--white">
                <div class="summary__img tile-img__container hide--tablet-down"><img [src]="formData?.locnimage"
                        [alt]="formData?.location"></div>
                <div class="tile-body form__vehicle-details">
                    <h4 class="heading--grouped">
                        <span
                            *ngIf="formData.servType">{{formData.servType === 'ServiceMOT' ? 'Service & MOT' : formData.servType}}
                            <span
                                *ngIf="formData.intervalMonth && !formData.isTextBased && !formData.isMOT">({{formData.intervalMonth}}
                                months)</span>
                            <span
                                *ngIf="formData.intervalMonth && formData.isTextBased">({{formData.intervalMonth}})</span><br>
                            <span
                                *ngIf="formData.annualInspect">({{formData.servAnnualInspectionLbl}})</span><br></span>
                        <span *ngIf="formData.seasonalCheck === 'Yes'">Seasonal Check <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalBronzeSelected | titlecase)  ===  'Yes'"> - Bronze</span>
                        <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalSilverSelected | titlecase)  ===  'Yes'"> - Silver</span>
                        <span *ngIf="(formData.servOptionCosts?.servOptionsSelected?.servSeasonalGoldSelected | titlecase)  ===  'Yes'"> - Gold</span></span>
                        <span *ngIf="formData.tyreCheck === 'Yes'">Tyre check<br></span>
                        <span *ngIf="formData.generalRepMain === 'Yes'">General Repair and Maintenance<br></span>
                        <span *ngIf="formData.recall === 'Yes'">Recall<br></span>
                        <span *ngIf="formData.healthCheck === 'Yes'">Health check<br></span>
                    </h4>
                    <p *ngIf="formData.courtesyCar === 'COURTESYCAR' || checkAdditionalServices()"
                        class="tile-text--grouped text--medium-grey text-size--medium">plus</p>
                    <h4 class="heading--grouped">
                        <span *ngIf="formData.courtesyCar === 'COURTESYCAR'">Courtesy car</span>
                        <span
                            *ngIf="formData.courtesyCar === 'COURTESYCAR' && formData.collectNdeliver === 'COLLECTANDDELIVER'">,&nbsp;</span>
                        <span *ngIf="formData.collectNdeliver === 'COLLECTANDDELIVER'">Collection & delivery</span>
                    </h4>
                    <h4 class="heading--grouped">
                        <div *ngFor="let option of formData.servTechOptions">
                            <span *ngIf="option.servTechSelected === 'yes'">{{ option.servTechLbl }}</span>
                        </div>
                    </h4>
                    <p class="tile-text--grouped text--medium-grey text-size--medium">at</p>
                    <h4 class="heading--grouped">{{formData.location}}</h4>
                    <p class="tile-text--grouped text--medium-grey text-size--medium">on</p>
                    <h4>{{formData.selectedDate}} <span class="text-size--medium"
                            *ngIf="formData.selectedTime !== ''">at</span> {{formData.selectedTime}}
                    </h4>

                    <ng-container *ngTemplateOutlet="totalCostsTemp"></ng-container>

                </div>
            </aside>
        </div>


        <div class="xs-col-12 m-col-3" *ngIf="vehicle">
            <aside class="form__summary tile__column tile--with-border-radius background--white">
                <div class="summary__img tile-img__container hide--tablet-down"><img [imgSrc]="formData?.image"
                        [alt]="formData?.vehicle" appImgLoader></div>
                <div class="tile-body form__vehicle-details">
                    <h4>{{formData?.vehicle}}</h4>
                    <p class="tile-text--grouped text--medium-grey text-size--medium">
                        {{formDataService.formData.vehicleReg}} |
                        {{formData.mileage}}
                        miles | {{formData?.basecolour}} | {{formData?.fuel}}</p>
                    <p class="text-size--medium">First registered: {{formData?.dofr}}</p>
                </div>
            </aside>
        </div>
    </div>
</div>

<ng-template #totalCostsTemp>
    <app-total-costs [formData]="formData" [hasHeadings]="!submitResponse"
        [displayServicePlanMessage]="getServiceDetailsService.displayServicePlanMessage$ | async"
        [moreOptionsSelected]="getServiceDetailsService.moreOptionsSelected"
        [hasDiscount]="getServiceDetailsService.hasDiscount" [totalDiscount]="getServiceDetailsService.totalDiscount"
        [serviceResponse]="getServiceDetailsService.serviceResponse">
    </app-total-costs>
</ng-template>
