import { HttpClient } from '@angular/common/http';
import { URLSearchParams } from '@angular/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EnquiryCreateService = /** @class */ (function () {
    function EnquiryCreateService(http) {
        this.http = http;
    }
    EnquiryCreateService.prototype.enquiryCreate = function (data) {
        var jsoncall = data;
        var params = new URLSearchParams();
        for (var key in jsoncall) {
            if (jsoncall.hasOwnProperty(key)) {
                params.set(key, jsoncall[key]);
            }
        }
        // console.log(params);
        return this.http.get('/content/api/json_enquirycreate.p?' + params.toString()
        // tslint:disable-next-line:max-line-length
        // Commented out as at the moment we need to work through the enquiry create with url params but moving forward we could work on passing in the model as header params
        // , {
        //   responseType: 'json',
        //   params: {
        //     jsoncall
        //   }
        // }
        );
    };
    EnquiryCreateService.ngInjectableDef = i0.defineInjectable({ factory: function EnquiryCreateService_Factory() { return new EnquiryCreateService(i0.inject(i1.HttpClient)); }, token: EnquiryCreateService, providedIn: "root" });
    return EnquiryCreateService;
}());
export { EnquiryCreateService };
