import { ElementRef } from '@angular/core';
import { BrandsConfigService } from '../services/brands-config.service';
var ImgLoaderDirective = /** @class */ (function () {
    function ImgLoaderDirective(_elRef, brandsConfigService) {
        this._elRef = _elRef;
        this.brandsConfigService = brandsConfigService;
        // The default image source when an error occurs
        this._errSrc = this.brandsConfigService.getConfigValue('myVehicleFallbackImg');
        // Bind the error handler to the class
        this._handleError = this._handleError.bind(this);
    }
    Object.defineProperty(ImgLoaderDirective.prototype, "imgSrc", {
        set: function (src) {
            // If the src has commas then separate it and get the first element
            if (/,/.test(src)) {
                var srcArray = src.split(',');
                src = srcArray[0];
            }
            // Set the image source and add an error handler
            this._elRef.nativeElement.src = src;
            this._elRef.nativeElement.addEventListener('error', this._handleError);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImgLoaderDirective.prototype, "errorSrc", {
        set: function (errSrc) {
            this._errSrc = errSrc;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Handle the image error to load the error source
     */
    ImgLoaderDirective.prototype._handleError = function () {
        this._elRef.nativeElement.src = this._errSrc;
        this._elRef.nativeElement.removeEventListener('error', this._handleError);
    };
    return ImgLoaderDirective;
}());
export { ImgLoaderDirective };
