import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLSearchParams } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class EnquiryCreateService {

  constructor(private http: HttpClient) {}

  enquiryCreate(data): Observable < any > {

    const jsoncall = data;

    const params = new URLSearchParams();
    for (const key in jsoncall) {
      if (jsoncall.hasOwnProperty(key)) {
        params.set(key, jsoncall[key]);
      }
    }
    // console.log(params);

    return this.http.get('/content/api/json_enquirycreate.p?' + params.toString()
      // tslint:disable-next-line:max-line-length
      // Commented out as at the moment we need to work through the enquiry create with url params but moving forward we could work on passing in the model as header params
      // , {
      //   responseType: 'json',
      //   params: {
      //     jsoncall
      //   }
      // }
    );
  }
}
