var DATE_REGEX = new RegExp("((((0[1-9])|([1-2][0-9])|30)\/(04|06|09|11))|(((0[1-9])|([1-2][0-9])|30|31)\/(01|03|05|07|08|10|12))|(((0[1-9])|([1-2][0-9]))\/02))\/([2-9][0-9]{3})");
// new RegExp("(THIRTY|THIRTY_ONE|FEB)\/YEAR");
var THIRTY = "(((0[1-9])|([1-2][0-9])|30)\/(04|06|09|11))";
var THIRTY_ONE = "(((0[1-9])|([1-2][0-9])|30|31)\/(01|03|05|07|08|10|12))";
var FEB = "(((0[1-9])|([1-2][0-9]))\/02)";
var YEAR = "([2-9][0-9]{3})";
var DateChecker = /** @class */ (function () {
    function DateChecker() {
    }
    DateChecker.checkDate = function (date) {
        return DATE_REGEX.test(date);
    };
    return DateChecker;
}());
export default DateChecker;
