import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Convert the provided number to a rounded number with 2 fixed decimals
 * @param num
 */
var toFixedDecimals = function (num) { return parseFloat((Math.round(num * 100) / 100).toFixed(2)); };
var ɵ0 = toFixedDecimals;
var GetServiceDetailsService = /** @class */ (function () {
    function GetServiceDetailsService(http) {
        this.http = http;
        this._discount = 0;
        this._applyDiscountMap = this._applyDiscountMap.bind(this);
        this._displayServicePlanMessage$ = new BehaviorSubject(false);
        this.displayServicePlanMessage$ = this._displayServicePlanMessage$.asObservable();
        this._moreOptionsSelected$ = new BehaviorSubject(false);
        this.moreOptionsSelected$ = this._moreOptionsSelected$.asObservable();
    }
    Object.defineProperty(GetServiceDetailsService.prototype, "displayServicePlanMessage", {
        get: function () {
            return this._displayServicePlanMessage$.value;
        },
        set: function (val) {
            this._displayServicePlanMessage$.next(val);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetServiceDetailsService.prototype, "moreOptionsSelected", {
        get: function () {
            return this._moreOptionsSelected$.value;
        },
        set: function (val) {
            this._moreOptionsSelected$.next(val);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetServiceDetailsService.prototype, "hasDiscount", {
        get: function () {
            return this._hasDiscount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetServiceDetailsService.prototype, "discount", {
        get: function () {
            return toFixedDecimals(this._discount * 100);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetServiceDetailsService.prototype, "totalDiscount", {
        get: function () {
            return toFixedDecimals(this._totalDiscount * 100);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetServiceDetailsService.prototype, "serviceResponse", {
        get: function () {
            return this._serviceResponse;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Apply the discount to the map observable as a callback
     * @param res The http response
     */
    GetServiceDetailsService.prototype._applyDiscountMap = function (res) {
        try {
            this._hasDiscount = res.servOptionCosts.servDiscountPercent !== '';
        }
        catch (error) {
            console.error(error);
            this._hasDiscount = false;
        }
        this._discount = this._hasDiscount ? parseFloat(res.servOptionCosts.servDiscountPercent) / 100 : 0;
        // Discount on total cost
        if (res && res.servOptionCosts && res.servOptionCosts.servTotalServiceCost && res.servOptionCosts.servServiceDiscount) {
            if (!/[a-z]/ig.test(res.servOptionCosts.servTotalServiceCost)
                && res.servOptionCosts.servTotalServiceCost !== ''
                && res.servOptionCosts.servServiceDiscount !== ''
                && res.servOptionCosts.servSelectedService !== '0') {
                var totalPriceNum = parseFloat(res.servOptionCosts.servTotalServiceCost);
                var serviceDiscountNum = parseFloat(res.servOptionCosts.servServiceDiscount);
                this._totalDiscount = toFixedDecimals(serviceDiscountNum / (totalPriceNum + serviceDiscountNum));
                // Store the original price to the response to display the discount
                res.servOptionCosts.originServTotalServiceCost = '' + (totalPriceNum + serviceDiscountNum);
            }
            else {
                // Delete the original service total cost if for some reason the response change to be on the safe side
                delete res.servOptionCosts.originServTotalServiceCost;
            }
        }
        this._serviceResponse = res;
        return res;
    };
    GetServiceDetailsService.prototype.getServiceDetails = function (vehicle, location, selectedDate, formData) {
        // The service plan message should only be displayed when the user has only selected Service
        // https://app.asana.com/0/751270265214859/1198888385952981/f
        if (formData.hasServicePlan) {
            var hasSelectedExtraOption = [
                formData.tyreCheck,
                formData.seasonalCheck,
                formData.healthCheck,
                formData.generalRepMain,
                formData.recall
            ].some(function (option) { return option === 'Yes'; });
            this.moreOptionsSelected = hasSelectedExtraOption;
            // this.displayServicePlanMessage = formData.servType === 'Service' && !hasSelectedExtraOption;
        }
        return this.http.get('/content/api/json_splanquote.p', {
            params: {
                vehspec: formData.specref,
                mileage: formData.mileage,
                dofr: formData.dofr,
                location: formData.location,
                servtype: formData.servType,
                seasonalCheck: formData.seasonalCheck,
                tyreCheck: formData.tyreCheck,
                adBlue: formData.adBlue,
                generalRepMain: formData.generalRepMain,
                recall: formData.recall,
                selectedService: formData.selectedService,
                courtesy: formData.courtesyCar,
                whileUwait: formData.whileUwait,
                collectNdeliver: formData.collectNdeliver,
                keyDropOff: formData.keyDropOff || '',
                regno: formData.vehicleReg,
                make: formData.make,
                model: formData.model,
                fuel: formData.fuel,
                postcode: formData.postcode,
                dateSelected: selectedDate,
                annualInspect: formData.annualInspect,
                healthCheck: formData.healthCheck,
                advisorAppointmentSts: formData.advisorAppointmentSts,
                techoptECS: formData.techoptECS,
                techoptACS: formData.techoptACS,
                techoptBFC: formData.techoptBFC,
                techoptCoolant: formData.techoptCoolant,
                techoptPollen: formData.techoptPollen,
                techoptGearbox: formData.techoptGearbox,
                techoptCambelt: formData.techoptCambelt,
                Q658: formData.Q658,
                seasonalDetox: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalDetoxSelected : '',
                seasonalAirConSrv: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalAirConSelected : '',
                seasonalCombined: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalCombinedSelected : '',
                seasonalBronze: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected : '',
                seasonalSilver: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected : '',
                seasonalGold: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected : '',
            }
        }).pipe(map(this._applyDiscountMap));
    };
    GetServiceDetailsService.prototype.getServiceDetailsWithoutDate = function (vehicle, location, formData) {
        // tslint:disable-next-line:max-line-length
        return this.http.get('/content/api/json_splanquote.p', {
            params: {
                vehspec: formData.specref,
                mileage: formData.mileage,
                dofr: formData.dofr,
                location: formData.location,
                servtype: formData.servType,
                seasonalCheck: formData.seasonalCheck,
                tyreCheck: formData.tyreCheck,
                adBlue: formData.adBlue,
                generalRepMain: formData.generalRepMain,
                recall: formData.recall,
                selectedService: formData.selectedService,
                courtesy: formData.courtesyCar,
                whileUwait: formData.whileUwait,
                collectNdeliver: formData.collectNdeliver,
                regno: formData.vehicleReg,
                make: formData.make,
                model: formData.model,
                fuel: formData.fuel,
                postcode: formData.postcode,
                dateSelected: formData.selectedDate,
                annualInspect: formData.annualInspect,
                healthCheck: formData.healthCheck,
                techoptECS: formData.techoptECS,
                techoptACS: formData.techoptACS,
                techoptBFC: formData.techoptBFC,
                techoptCoolant: formData.techoptCoolant,
                techoptPollen: formData.techoptPollen,
                techoptGearbox: formData.techoptGearbox,
                techoptCambelt: formData.techoptCambelt,
                Q658: formData.Q658,
                seasonalDetox: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalDetoxSelected : '',
                seasonalAirConSrv: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalAirConSelected : '',
                seasonalCombined: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalCombinedSelected : '',
                seasonalBronze: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalBronzeSelected : '',
                seasonalSilver: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalSilverSelected : '',
                seasonalGold: formData.servOptionCosts ? formData.servOptionCosts.servOptionsSelected.servSeasonalGoldSelected : '',
            }
        }).pipe(map(this._applyDiscountMap));
    };
    GetServiceDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function GetServiceDetailsService_Factory() { return new GetServiceDetailsService(i0.inject(i1.HttpClient)); }, token: GetServiceDetailsService, providedIn: "root" });
    return GetServiceDetailsService;
}());
export { GetServiceDetailsService };
export { ɵ0 };
