
// Personal Details Component
import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { FormData, EnquiryCreate, AddressList } from 'src/app/models/formData';
import { Vehicle } from 'src/app/models/vehicle';
import { FormDataService } from 'src/app/services/form-data.service';
import { EnquiryCreateService } from 'src/app/services/enquiry-create.service';
import { GetServiceDetailsService } from 'src/app/services/get-service-details.service';
import { CountiesService } from '../../services/counties.service';
import { BrandsConfigService } from 'src/app/services/brands-config.service';

@Component({
    selector: 'app-personal-details',
    templateUrl: './personal-details.component.html',
    viewProviders: [{
        provide: ControlContainer,
        useExisting: NgForm
    }]
})

export class PersonalDetailsComponent implements OnInit, AfterViewInit {
    vehicle: Vehicle;
    formData: FormData;
    enquiryCreate: EnquiryCreate;
    addressList: AddressList;
    submitResponse: any;
    termsAccepted: boolean;
    counties: string[];

    regexPatterns: object = {
        phoneUK: /^((\(?0\d{4}\)?\s*\d{6}\s*)|(\s*\(?0\d{3}\)?\s*\d{3}\s*\d{4})|(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3})$/,
        email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
        name: /^([a-z,.'-]+\s?)+$/i,
      }

    // Output response
    @Output() formResponse: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public formDataService: FormDataService,
        private enquiryCreateService: EnquiryCreateService,
        public getServiceDetailsService: GetServiceDetailsService,
        public brandsConfigService: BrandsConfigService,
        public countiesService: CountiesService) { }

    ngOnInit() {
        this.formData = this.formDataService.getFormData();
        this.enquiryCreate = this.formDataService.getEnquiryData();
        this.addressList = this.formDataService.getAddressLineData();
        this.termsAccepted = false;
        this.counties = Object.keys(this.countiesService.getCounties());
    }

    ngAfterViewInit() {
        // this.formDataService.setDataLayer(window.location.href + 'personal-details', 'Personal details page of service booking form');
    }

    submitData(enquiryCreate: EnquiryCreate) {
        // alert('This will submit the form and return a nice confirmation to the user.');
        this.vehicle = this.formDataService.getVehicle();
        if (this.formData.postcodeFound) {
          this.formData.Address_2 = this.addressList.addressLines.Addresslines[0].Address_2;
          this.formData.Town = this.addressList.addressLines.Addresslines[0].Town;
          this.formData.County = this.addressList.addressLines.Addresslines[0].County;
        }

        if (this.getServiceDetailsService.hasDiscount) {
          this.formData.comments += ` - A service discount of ${this.getServiceDetailsService.discount}% has been applied`;
        }

        this.formDataService.setEnquiryJSONCreate(this.formData, this.vehicle);
        // console.log('Called submitData' +  JSON.stringify(this.enquiryCreate));
        this.enquiryCreateService.enquiryCreate(this.enquiryCreate).subscribe(
            (data: any) => {
                this.submitResponse = data;
                this.formResponse.emit(false);
                // console.log('Successful Response');
            },
            (err: any) => console.log(err)
            //, () => console.log(this.submitResponse)
        );
        this.formDataService.scrollToTop();

        this.updateDataLayer();
        console.log(this.formData);
    }

    updateDataLayer() {
        this.formDataService.setDataLayer(window.location.href + 'personal-details', 'service booking form submitted');
        (window as any).dataLayer.push({ event: 'serviceBookingComplete' });
        this.formDataService.setDataLayerDetails();
    }

    addressToId(addr: string): string {
        return addr.replace(/\s/g, '-');
    }

    checkAdditionalServices() {
        if (this.formData.servTechOptions) {
            for (let i = 0; i < this.formData.servTechOptions.length; i++) {
                if (this.formData.servTechOptions[i].servTechSelected === 'yes') {
                    return true;
                }
            }
        }
    }
}
