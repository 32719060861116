
// Service Booking Form Component
import { Component, OnInit, ViewChild } from '@angular/core';
// import { FormDataService } from '../services/form-data.service';
// import { VehicleLookupService } from '../services/vehicle-lookup.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatHorizontalStepper } from '@angular/material';
import { BrandsConfigService } from '../services/brands-config.service';

declare var qBrandRef: string;
declare var hideTestElements: boolean;
declare var title: string;
declare var subtitle: string;

@Component({
  selector: 'app-service-booking-form',
  templateUrl: './service-booking-form.component.html',
  // styleUrls: ['./service-booking-form.component.css'],
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})

export class ServiceBookingFormComponent implements OnInit {

  formDisabled: boolean;
  sellsBikes = false;
  vehicleName: string;
  appTitle: string;
  appSubtitle: string;
  @ViewChild('stepper') stepper: MatHorizontalStepper;

  constructor(private brandsConfigService: BrandsConfigService) {
  }

  ngOnInit() {
    // Set formDisabled to true initially
    this.formDisabled = true;
    this.vehicleName = this.brandsConfigService.getVehicleName();

    if (typeof title !== 'undefined' && title !== null) {
      this.appTitle = title;
    }

    if (typeof subtitle !== 'undefined' && subtitle !== null) {
      this.appSubtitle = subtitle;
    }

    // If brand ref is available
    if (typeof qBrandRef !== 'undefined' && qBrandRef !== null) {
      // If brand ref sells bikes
      if (qBrandRef === '84') {
        this.sellsBikes = true;
      }
    }


    // Fire event when user goes to a step
    if (typeof hideTestElements !== 'undefined' && hideTestElements) {
      const backToFirstStageLink = document.querySelector('.js-to-prev-stage');
      // Go back a step
      if (backToFirstStageLink) {
        backToFirstStageLink.addEventListener('click', () => {
          this.toPreviousStage(this.stepper.selectedIndex, this.stepper);
        });
      }
      // Subscribe to selection change event
      this.stepper.selectionChange.subscribe(event => {
        if (event.selectedIndex > 0) {
          this.handleInitialStageElements();
        } else {
          this.handleStageElements();
          this.scrollToForm();
        }
      });
    }
  }

  // Back to previous stage
  toPreviousStage(index: number, stepper: MatHorizontalStepper) {
    setTimeout(() => {
      stepper.selectedIndex = index - 1;
    });
  }

  // Once response from submitData has ran, set the formDisabled
  // new status
  successfulResponse(formStatus: boolean): void {
    this.formDisabled = formStatus;
  }

  // Hide all elements for the AB test from the first stage
  handleInitialStageElements() {
    const hideElements = Array.from(document.querySelectorAll('.js-hide-element-on-service-booking-stages'));
    const toShowElements = Array.from(document.querySelectorAll('.js-show-element-on-service-booking-stages.hide__default'));
    if (hideElements) {
      hideElements.forEach(hideEl => {
        hideEl.classList.add('hide__default');
      });
    }

    if (toShowElements) {
      toShowElements.forEach(showEl => {
        showEl.classList.remove('hide__default');
      });
    }
  }

  // Handle stage elements after first stage
  handleStageElements() {
    const hiddenElements = Array.from(document.querySelectorAll('.js-hide-element-on-service-booking-stages.hide__default'));
    const showElements = Array.from(document.querySelectorAll('.js-show-element-on-service-booking-stages'));
    if (hiddenElements) {
      hiddenElements.forEach(hideEl => {
        hideEl.classList.remove('hide__default');
      });
    }

    if (showElements) {
      showElements.forEach(showEl => {
        showEl.classList.add('hide__default');
      });
    }
  }

  // Scroll to form when back on first page
  scrollToForm() {
    const form48 = document.getElementById('form_48');
    if (form48) {
      const yOffset = -100;
      const y = form48.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }
}
