// Service Booking Form Component
import { OnInit } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material';
import { BrandsConfigService } from '../services/brands-config.service';
var ɵ0 = { showError: true };
var ServiceBookingFormComponent = /** @class */ (function () {
    function ServiceBookingFormComponent(brandsConfigService) {
        this.brandsConfigService = brandsConfigService;
        this.sellsBikes = false;
    }
    ServiceBookingFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Set formDisabled to true initially
        this.formDisabled = true;
        this.vehicleName = this.brandsConfigService.getVehicleName();
        if (typeof title !== 'undefined' && title !== null) {
            this.appTitle = title;
        }
        if (typeof subtitle !== 'undefined' && subtitle !== null) {
            this.appSubtitle = subtitle;
        }
        // If brand ref is available
        if (typeof qBrandRef !== 'undefined' && qBrandRef !== null) {
            // If brand ref sells bikes
            if (qBrandRef === '84') {
                this.sellsBikes = true;
            }
        }
        // Fire event when user goes to a step
        if (typeof hideTestElements !== 'undefined' && hideTestElements) {
            var backToFirstStageLink = document.querySelector('.js-to-prev-stage');
            // Go back a step
            if (backToFirstStageLink) {
                backToFirstStageLink.addEventListener('click', function () {
                    _this.toPreviousStage(_this.stepper.selectedIndex, _this.stepper);
                });
            }
            // Subscribe to selection change event
            this.stepper.selectionChange.subscribe(function (event) {
                if (event.selectedIndex > 0) {
                    _this.handleInitialStageElements();
                }
                else {
                    _this.handleStageElements();
                    _this.scrollToForm();
                }
            });
        }
    };
    // Back to previous stage
    ServiceBookingFormComponent.prototype.toPreviousStage = function (index, stepper) {
        setTimeout(function () {
            stepper.selectedIndex = index - 1;
        });
    };
    // Once response from submitData has ran, set the formDisabled
    // new status
    ServiceBookingFormComponent.prototype.successfulResponse = function (formStatus) {
        this.formDisabled = formStatus;
    };
    // Hide all elements for the AB test from the first stage
    ServiceBookingFormComponent.prototype.handleInitialStageElements = function () {
        var hideElements = Array.from(document.querySelectorAll('.js-hide-element-on-service-booking-stages'));
        var toShowElements = Array.from(document.querySelectorAll('.js-show-element-on-service-booking-stages.hide__default'));
        if (hideElements) {
            hideElements.forEach(function (hideEl) {
                hideEl.classList.add('hide__default');
            });
        }
        if (toShowElements) {
            toShowElements.forEach(function (showEl) {
                showEl.classList.remove('hide__default');
            });
        }
    };
    // Handle stage elements after first stage
    ServiceBookingFormComponent.prototype.handleStageElements = function () {
        var hiddenElements = Array.from(document.querySelectorAll('.js-hide-element-on-service-booking-stages.hide__default'));
        var showElements = Array.from(document.querySelectorAll('.js-show-element-on-service-booking-stages'));
        if (hiddenElements) {
            hiddenElements.forEach(function (hideEl) {
                hideEl.classList.remove('hide__default');
            });
        }
        if (showElements) {
            showElements.forEach(function (showEl) {
                showEl.classList.add('hide__default');
            });
        }
    };
    // Scroll to form when back on first page
    ServiceBookingFormComponent.prototype.scrollToForm = function () {
        var form48 = document.getElementById('form_48');
        if (form48) {
            var yOffset = -100;
            var y = form48.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };
    return ServiceBookingFormComponent;
}());
export { ServiceBookingFormComponent };
export { ɵ0 };
