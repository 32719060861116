import 'hammerjs';
import { MatDateFormats } from '@angular/material';
var MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};
var ɵ0 = { strict: true }, ɵ1 = MY_FORMATS, ɵ2 = {
    showError: true
}, ɵ3 = window['base-href'];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
