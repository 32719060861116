import { BRANDS, BRAND_CONFIGS, VEHICLE_TYPE_MAP } from '../utils/config';
import * as i0 from "@angular/core";
/**
 * Provides text/url localizations for the car/motorcycle brands depending on the window.location.href.
 */
var BrandsConfigService = /** @class */ (function () {
    /**
     * Initialise the brand configuration depending on the current url.
     */
    function BrandsConfigService() {
        var href = window.location.href;
        if (href.includes('vertumotorcycles')) {
            this.brand = BRANDS.MOTORCYCLE;
        }
        else {
            this.brand = BRANDS.CAR;
        }
    }
    /**
     * Retrieve the current brand's config value for the given key.
     * Use @function getVehicleName to retrieve the current environment's vehicle name translation.
     * @param key
     */
    BrandsConfigService.prototype.getConfigValue = function (key) {
        return BRAND_CONFIGS[this.brand][key];
    };
    /**
     * Check the current brand's configuration whether a property/field should be hidden.
     * @param property
     */
    BrandsConfigService.prototype.isHidden = function (property) {
        if (BRAND_CONFIGS[this.brand]["hide" + (property.charAt(0).toUpperCase() + property.slice(1))] === 'true')
            return true;
        return false;
    };
    /**
     * Get the vehicle name for the given vehicle type using the lookup table or, if omitted,
     * look it up in the current brand's config settings. The former being the case when a brand
     * supports multiple vehicleTypes which should be localized depending on current circumstances (i. e. entered registration).
     * @param plural
     * @param vehType
     */
    BrandsConfigService.prototype.getVehicleName = function (plural, vehType) {
        if (plural === void 0) { plural = false; }
        if (vehType) {
            var matchingKey = void 0;
            for (var _i = 0, _a = Array.from(VEHICLE_TYPE_MAP.entries()); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (value.includes(vehType.toLowerCase())) {
                    matchingKey = key;
                    break;
                }
            }
            if (matchingKey) {
                return plural ? matchingKey + "s" : matchingKey;
            }
        }
        return plural ? BRAND_CONFIGS[this.brand].vehicles : BRAND_CONFIGS[this.brand].vehicle;
    };
    BrandsConfigService.ngInjectableDef = i0.defineInjectable({ factory: function BrandsConfigService_Factory() { return new BrandsConfigService(); }, token: BrandsConfigService, providedIn: "root" });
    return BrandsConfigService;
}());
export { BrandsConfigService };
